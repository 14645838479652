import {MetaRoomHero} from "../../components/MetaRoomHero";
import { MetaSliderHero } from "../../components/MetaSliderHero/";

export default function MetaRoom() {
  return (
    <>
      <MetaRoomHero />
      <MetaSliderHero />
    </>
  );
}
