import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'

//center;
//flex-start
//height: auto;

//height: 100vh;
const Container = styled.section`
display: flex;
align-items: self-end;

height: calc(100vh - 100px);
@media screen and (max-width:1000px) {
    height: calc(100vh - 50px);
}

	${tw`
    //ml-[150px]

    // mt-[151px]
    // mb-[140px]
    // sm:mt-[550px]
    text-center
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-lg text-xxls
    lg:mb-[96px] mb-[38px] text-center
    lg:px-[60px] px-[20px]
`}
`

const Btn = styled.button`
	:before {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.1) 100%
		);
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
	cursor: default;
    display: block;
    margin: auto;
	${tw`
    before:lg:rounded-[20px]
    before:lg:p-[3px]
    before:rounded-[10px]
    before:p-[2px]
    relative
    lg:p-[9px 28px]
    p-[18px 38px]
    lg:text-2xl
    font-normal
    text-xxls
    font-syncopate
    uppercase
    //mt-[296px]
    //lg:mt-[296px]
    // lg:mb-[96px]
    //sm:mb-[296px]
`}
`

// Видео обертка

// const Container = styled.div`
// 	${tw`
//     relative
//     p-[0px 0px 54% 0px]
//     mb-[78px]
//     shadow-[0 100px 100px  #50d8fabf]
// `}
// @media screen and (max-width:600px) {
// padding:0 0 0;
// margin-bottom:0;
// }
// `

// Видео

const Videos = styled.video`
display:none;
@media screen and (max-width:6000px) {
display:block;}
	${tw`
  absolute
  top-0
  left-0
  w-full
  h-full
  object-cover
`}
`

const BlockFull = styled.div`
display:none;
@media screen and (min-width:600px) {
display:block;}
`

const PaddingTop = styled.div`
padding-top: 100px;
`

//height: 100vh;
//margin-top: 60%;
export const TitleRoom = styled.h1`
margin-bottom: 25px;
	:before {
		position: absolute;
		left: 50%;
		height: 2px;
		transform: translateX(-50%);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[291px]
    before:w-[195px] 
    before:lg:bottom-[-28px]
    before:bottom-[-20px]
    relative
    font-syncopate
    text-center
    lg:text-5xl
    text-4xl
    uppercase
    text-white
`}
`

//    mt-[h-1/2]
//h-full


export const WrapperRoom = styled.div`
  ${tw`
  mx-auto
  lg:max-w-[1440px]
  w-screen
  px-[30px]
  lg:px-[100px]
`}
`;


export const MetaRoomHero: React.FC = () => {
	return (
        <>
        {/* <Container>
            123
        </Container> */}
		<Container>
			<Videos autoPlay loop muted playsInline>
				<source
					src='./assets/video/metaroom.mp4'
					type='video/mp4'
					media='screen and (max-width:6000px)'
				/>
			</Videos>

            <WrapperRoom>
                {/* <TitleRoom className='mb-[25px] mt-[450px]'>Metaroom</TitleRoom> */}
                <TitleRoom>Metaroom</TitleRoom>

                <Btn>After preview scroll down</Btn>
            </WrapperRoom>
		</Container>
        {/* <PaddingTop/> */}
        </>
	)
}
