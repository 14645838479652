import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'

// Контейнер для секции с отступом

const Container = styled.section`
	${tw`
    mb-[210px]
    lg:mt-[150px]
    mt-[83px]
    mb-[141px]
    text-center
`}
`

// Тайтл

const Title = styled.h1`
	:before {
		position: absolute;
		bottom: -25px;
		left: 50%;
		transform: translateX(-50%);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:h-[2px]
    before:lg:w-[291px]
    before:w-[225px] 
    relative
    font-syncopate
    lg:mb-[172px]
    mb-[72px]
    text-white
    font-normal
    lg:text-3xl
    text-4xl
    uppercase
    text-center
`}
`

// Контейнер для карточек

const CardContainer = styled.div`
	${tw`
    lg:pl-[18px] lg:pr-[3px]
    lg:w-[65%] lg:mx-[auto]
    flex flex-col items-center
    lg:block
`}
`

// Карточка

const Card = styled.div`
	${tw`
    font-montserrat
    text-white
    lg:w-[300px]
    w-[374px]
    // lg:mb-[113px]
    // mb-[50px]
    lg:first:mr-auto
    lg:even:ml-auto
`}
@media (max-width:500px) {
	width:100%;
	height:auto;
}
`

const CardBg = styled.div<{
	lineaA?: boolean
}>`
	background: linear-gradient(
		110.02deg,
		#d3e1e8 -2.46%,
		#96cfdc 59.07%,
		#97afc4 109.96%
	);
	border-radius: 20px;
	box-shadow: 0px 0px 106px rgba(240, 240, 240, 0.3);
	@media (min-width: 1024px) {
		:after {
			position: absolute;
			bottom: 50%;
			${({ lineaA }) => (lineaA ? 'right: 100%;' : 'left: 100%;')}
			transform: translateY(-50%);
			width: 100vw;
			height: 3px;
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			content: '';
		}
	}
	${tw`
    lg:w-[300px]
    w-[373px]
    h-[310px]
    lg:h-[auto]
    lg:mb-[50px]
    lg:pb-[20px]
    mb-[38px]
    relative
    flex flex-col justify-start items-center
`}
@media (max-width:500px) {
width:100%;
	height:auto;
 padding-bottom:10px
}
`

// Контейнер для иконок в карточке

const ImageContainer = styled.div`
	border-radius: 20px;
	@media (max-width: 1023px) {
		img {
			width: 115px;
			height: 115px;
		}
	}
	${tw`
    lg:w-[80%] lg:h-[auto]
    w-[218px] h-[175px]
    lg:px-[29px] lg:py-[29px]
    flex items-center justify-center
    lg:mt-[20px]
    mt-[49px]
    lg:mb-[20px]
    mb-[25px]
    bg-white
    z-20
    relative
`}
`

// Текст на карточках с треугольником

const TextTriangle = styled.span<{
	market?: boolean
	triangle?: boolean
}>`
	transform: translate(-50%, -50%);
	${tw`
    absolute
    uppercase
`}
	${({ market, triangle }) =>
		market
			? tw`text-smsm lg:text-smlg font-semibold top-[42%] left-[55%] lg:left-[53%]`
			: triangle
			? tw`top-[45%] lg:text-4xl left-[48%] text-xxls font-semibold`
			: tw`top-1/2 lg:text-4xl text-xxls font-semibold left-[53%]`}
`

// Маски под иконкой первой карточки

const Mask = styled.img<{
	one?: boolean
	two?: boolean
	oneMob?: boolean
	twoMob?: boolean
	threeMob?: boolean
}>`
	${tw`
    absolute
    z-10
`}
	${({ one, two, oneMob, twoMob, threeMob }) =>
		one
			? tw`display[none] lg:block right-[90px] top-[18px]`
			: two
			? tw`display[none] lg:block right-[90px] top-[110px]`
			: oneMob
			? tw`lg:display[none] right-[140px] top-[15px]`
			: twoMob
			? tw`lg:display[none] right-[60px] top-[10px]`
			: threeMob
			? tw`lg:display[none] right-[60px] top-[60px]`
			: tw`display[none] lg:block right-[150px] top-[70px]`}
`

// Тайтл карточки

const CardTitile = styled.h2`
	${tw`
    lg:text-2xl
    text-2xl
    uppercase
    font-medium
`}
`

// Описание карточки
// capitalize
const CardText = styled.p`
	${tw`
    lg:text-lg
    lg:px-[10px]
    px-[15px]
    text-lg
    font-normal
    text-center
`}
`
export const ProjectsCard: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<Title>Our projects</Title>
				<CardContainer>
					<Card>
						<CardBg>
							<ImageContainer >
								<img src='./assets/images/card_avatar.png' alt='' />
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>Nft sensay</CardTitile>
							<CardText>
								Our NFT project is an integral part of a large and interacting
								ecosystem, the Lumeta ecosystem. Each successive project connects
								to the previous one
							</CardText>
						</CardBg>
					</Card>
					<Card>
						<CardBg lineaA>
							<ImageContainer>
								<img src='./assets/vector/card_token.svg' alt='' />
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>Token</CardTitile>
							<CardText>
                            Token represents an economic cryptosystem for payment transactions of physical businesses, creation of liquidity and passive income for NFT owners
							</CardText>
						</CardBg>
					</Card>
					<Card>
						<CardBg>
							<ImageContainer>
								<img src='./assets/vector/card_meditation.svg' alt='' />
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>App - Meditate to earn</CardTitile>
							<CardText>
                            The App - Meditate to Earn is a new round of development and integration of real life, crypto technologies and the path of enlightenment
							</CardText>
						</CardBg>
					</Card>
					<Card>
						<CardBg lineaA>
							<ImageContainer>
								<img src='./assets/vector/card_triangle.svg' alt='' />
								<TextTriangle>nft</TextTriangle>
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>NFT 2</CardTitile>
							<CardText>
                            In development
							</CardText>
						</CardBg>
					</Card>
					<Card>
						<CardBg>
							<ImageContainer>
								<img src='./assets/vector/card_4.svg' alt='' />
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>SWAP</CardTitile>
							<CardText>
                            In development
							</CardText>
						</CardBg>
					</Card>
					<Card>
						<CardBg lineaA>
							<ImageContainer>
								<img src='./assets/vector/card_triangle_2.svg' alt='' />
								<TextTriangle>nft</TextTriangle>
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>NFT 3</CardTitile>
							<CardText>
                            In development
							</CardText>
						</CardBg>
					</Card>
					<Card>
						<CardBg>
							<ImageContainer>
								<img src='./assets/vector/card_3.svg' alt='' />
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>Crypto</CardTitile>
							<CardText>
                            In development
							</CardText>
						</CardBg>
					</Card>
					<Card>
						<CardBg lineaA>
							<ImageContainer>
								<img src='./assets/vector/card_5.svg' alt='' />
								<TextTriangle market>marketplace</TextTriangle>
							</ImageContainer>
							<Mask one src='./assets/images/mask_card_1.png' alt='' />
							<Mask two src='./assets/images/mask_card_2.png' alt='' />
							<Mask src='./assets/images/mask_card_3.png' alt='' />
							<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
							<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
							<Mask threeMob src='./assets/images/mask_card_3_mob.png' alt='' />
							<CardTitile>Marketplace</CardTitile>
							<CardText>
                            In development
							</CardText>
						</CardBg>
					</Card>
				</CardContainer>
			</Wrapper>
		</Container>
	)
}
