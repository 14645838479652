import { Questions } from "../../components/questions";
import {MusicPlayer} from "../../components/musicPage";


export default function Faq() {
  return (
    <>
      <Questions />
    </>
  );
}
