import React, {Key, useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'
import { Links } from './link'
import {MusicPlayer} from "../musicPage";
import {useInView} from "react-intersection-observer";
import {Link, NavLink} from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { matchPath, useLocation } from 'react-router';
// Футер

const Container = styled.footer`
	${tw`
    lg:px-[30px]
    px-[35px]
    lg:mb-[61px]
    mb-[44px]
`}
`

// Контейнер под все колонки

const ListContainer = styled.div`
	${tw`
    flex lg:items-start flex-row 
    flex-wrap lg:h-[400px]
    lg:mb-[0] mb-[49px]
`}
`

// Контейнер под колонки

const List = styled.ul<{
	imgList?: boolean
}>`
	${({ imgList }) =>
		imgList
			? tw`flex flex-wrap items-center justify-between 
w-full lg:pl-[15px]`
			: tw`
        lg:w-[16.66%]
        [&:nth-child(1)]:mb-[31px]
        [&:nth-child(2)]:mb-[31px]
        w-1/2
        [&:nth-child(2)]:lg:pl-[35px]
        [&:nth-child(3)]:lg:pl-[34px] lg:w-[20%] max-w-[178px]
        [&:nth-child(4)]:lg:pl-[50px]
        [&:nth-child(5)]:lg:pl-[50px]
        [&:nth-child(6)]:lg:pl-[50px]
        [&:nth-child(5)]:lg:h-[187px]
        [&:nth-child(1)]:lg:order-1
        [&:nth-child(2)]:lg:order-2
        [&:nth-child(3)]:lg:order-3
        [&:nth-child(4)]:lg:order-4
        [&:nth-child(5)]:lg:order-5
        [&:nth-child(6)]:lg:order-6
        [&:nth-child(1)]:order-1
        [&:nth-child(2)]:order-3
        [&:nth-child(3)]:order-2
        [&:nth-child(4)]:order-5
        [&:nth-child(5)]:order-6
        [&:nth-child(6)]:order-4`}
`

// Один элемент списка
const socialLink = styled.a`
`
const Item = styled.li<{
	imgItem?: boolean
	post?: boolean
}>`
	${tw`
    first:lg:text-xl
    first:text-md
    first:text-footer-title
    first:font-semibold
     first:cursor-default
    cursor-pointer
    
`}
	${({ imgItem, post }) =>
		imgItem
			? tw`mb-[30px] lg:mb-[36px] w-1/2`
			: post
			? tw`lg:text-xl text-md
    text-footer-title
    font-semibold
    tracking-wider
    font-montserrat
    uppercase
    lg:mt-[70px]`
			: tw`tracking-wider
    lg:text-lg text-smxl
    font-medium
    font-montserrat
    uppercase
    text-footer
    lg:mb-[10px] 
    mb-[15px]
    first:mb-[19px]
    first:lg:mb-[26px]`}
`

// Кнопка инфо

const InfoBtn = styled.button`
	background: linear-gradient(
		110.02deg,
		#d3e1e8 -2.46%,
		#96cfdc 59.07%,
		#97afc4 109.96%
	);
	border-radius: 10px;
	${tw`
    lg:opacity-70
    lg:px-[18px]
    px-[12px]
    lg:py-[18px]
    py-[10px]
    xl:mt-[0]
    lg:mt-[0]
    xl:w-[197px] lg:h-[52px]
    w-[153px] h-[36px]
    font-semibold 
    tracking-wider
    font-montserrat
    xl:text-smxl
    text-smsm
    uppercase
    text-white
    relative
`}
p{
${tw`
absolute
top-[100%]
left-[50%]
text-black
text-lg
font-light
`}
transform:translate(-50%,0);
}
`

// Контейнер под копирайтинг

const CopyContainer = styled.div`
	${tw`
      lg:justify-between lg:items-center lg:flex
    items-start
	m-[auto]
`}
`

// Контейнеры для левой и правой части копирайтинга

const CopyContent = styled.div<{
	left?: boolean
}>`
	${({ left }) =>
		left
			? tw`mb-[17px] lg:mb-[initial]`
			: tw`w-full lg:w-[initial] lg:block m-[20px 0 20px 0] justify-between`}
`

// Логотип футера

const Logo = styled.img<{
	logoMob?: boolean
}>`
	${({ logoMob }) =>
		logoMob
			? tw`lg:display[none] mb-[16px]`
			: tw`mb-[14px] lg:block display[none]`}
`

// Тайтл копирайтинга

const CopyTitle = styled.p`
	${tw`
    mb-[14px]
    lg:ml-[20px]
    ml-[12px]
    text-footer
    font-montserrat
    lg:font-bold
    font-semibold
    tracking-wider
    lg:text-md
    text-smsml
`}
`

// Текст под копирайтингом

const CopyText = styled.p<{
	reserved?: boolean
}>`
	${tw`
    font-montserrat
    tracking-wider
    uppercase
`}
	${({ reserved }) =>
		reserved
			? tw`text-footer-gray text-smxxl lg:text-md underline font-bold`
			: tw`lg:text-lg text-smlg text-footer-copy font-semibold lg:font-bold`}
`

// Флекс контейнер для значка

const CopyWrite = styled.div`
	${tw`
    flex lg:items-center items-start
`}
	img {
		padding-bottom: 10px;
	}
`

const ListLink: React.FC<{
	link?: any
}> = ({ link }) => {
	const [btn,setBtn] = useState<boolean>(false)
	function bCopie(e:any) {
		console.log('click')
		let text:any = e.target;
		let child:any = document.querySelector('.btn-cop') as HTMLSpanElement | null;
		setBtn(true)
		setTimeout(() => {
			setBtn(false)
		},500)
		if( typeof (navigator.clipboard) != 'undefined') {
			navigator.clipboard.writeText(child.innerHTML);
		}
	}
	return (
		<List>
			{link.map((item: any, index: Key | null | undefined) => {
				if (typeof item === 'object' && item.src) {
					return (
						<List key={index} imgList>
							{item.links.map((x: any, index: Key | null | undefined) => {
								if(x['img'] === null){
									return (
										<Item key={index}>
											{x['name']}
										</Item>
									)
								} else{
									return (
									<Item key={index} imgItem>
										<a target='_blank' href={x['href']} rel="noreferrer">
										<img src={x['img']} alt='' width={ x['img'] === 'assets/images/logo_nft3.png' ? '41px':''} />
										</a>
									</Item>
								)}
							})}
						</List>
					)
				}
				if (typeof item === 'object' && item.name) {
					if(item.name === 'about us' || item.name === 'products' || item.name === 'support' || item.name === 'contacts') {
						return (<Item key={index}>{item.name}</Item>)
					}
					if( item.name === 'nft') {
					return	<Item key={index}><a href={item.href} target='_blank' rel="noreferrer">{item.name}</a></Item>
					}
					return(
						<Item key={index}><Link to={item.href} target={ item.name === 'nft'?'_blank':''} >{item.name}</Link></Item>
					);
				}
				if (typeof item === 'object' && item.btn) {
					return (
						<li className='h-[200px]' key={index}>
							<InfoBtn onClick={bCopie} ><span className='btn-cop'>{item.btn}</span>
								<p className={btn?'block':'hidden'}>copied</p>
							</InfoBtn>
						</li>
					)
				}
			})}
		</List>
	)
}


export const Footer: React.FC = () => {
	const {ref , inView} = useInView({
		threshold:0,
	})
	const [vis,setVis] = useState<boolean>(false)
	const check = useEffect(() => {
		inView ? setVis(true):setVis(false);
	},[inView])

    const { pathname } = useLocation();
    const isMetaroomPath = matchPath(pathname, "/metaroom");
    let isButtonPlayer = isMetaroomPath ? false: true;

    const audioRef = useRef(new Audio('./assets/audio/metaroom.mp3'));
    audioRef.current.loop = true;
    isButtonPlayer ? audioRef.current.pause() : audioRef.current.play();

	return (
		<Container ref={ref}>
			<Wrapper>
				<ListContainer>
					{Links.map((link: any, index) => {
						return <ListLink key={index} {...link} />
					})}
				</ListContainer>
				<CopyContainer>
					<CopyContent>
						<Logo logoMob src='assets/images/lumeta_footer_mob.png' alt='' />
						<Logo src='assets/images/lumeta_footer.png' alt='' />
						<CopyText>Follow our illuminated club hier!</CopyText>
					</CopyContent>
					<CopyContent className='lg:width-[435px] lg:ml-[auto] lg:mr-[0px]'>
						<CopyWrite>
							<img src='assets/vector/copywrite.svg' alt='' />
							<CopyTitle>2024 Lumeta</CopyTitle>
						</CopyWrite>
						<CopyText reserved>All rigts reserved.</CopyText>
					</CopyContent>
				</CopyContainer>
			</Wrapper>

            <MusicPlayer check={vis} stop={!isButtonPlayer} /> 

			<CookieConsent
				location="bottom"
				buttonText="I Agree"
				cookieName="LumetaCookie34567"
				style={{ background: "white",color:"#2f9de1",width :"326px",left:"15px",bottom:"15px",borderRadius:"20px 20px 0 0"}}
				buttonStyle={{ background:"#1f98c0",color:"white",marginRight:"10px" }}
				expires={150}
				declineButtonText='I Do Not Agree'
				declineButtonStyle={{background:"transparent",color:"#0fa3d4",textDecoration:"underline",marginLeft:"0"}}
				enableDeclineButton flipButtons
				onDecline = { () => { window.location.reload()} }
				overlay={window.location.pathname === '/terms' ? false : true}
			>
				Use of this website is at the user's
				own risk and Lumeta is not liable
				for any incorrect or failed
				transaction. For more, <NavLink to={'/terms'} style={{textDecoration:"underline"}}>Terms</NavLink>.
			</CookieConsent>
		</Container>
	)
}
