import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
import About from './pages/about';
import Faq from './pages/faq';
import Home from './pages/home';
import Investors from './pages/investors';
import Projects from './pages/projects';
import Roadmap from './pages/roadmap';
import Shop from './pages/shop';
import Terms from './pages/terms';
import Privacy from "./pages/privacy";
import Careers from "./pages/careers";
import Blog from "./pages/Blog";
import Risk from "./pages/risk";
import ContactBusiness from "./pages/contactBusiness";
import Contact from "./pages/contact";
import Feedback from "./pages/feedback";
import Learn from "./pages/learn";
import AnnualReport from "./pages/AnnualReport";
import MetaRoom from "./pages/metaroom";

export default function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/careers" element={<Careers/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/risk" element={<Risk/>} />
        <Route path="/business" element={<ContactBusiness/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/learn" element={<Learn/>} />
        <Route path="/feedback" element={<Feedback/>} />
        <Route path="/annualreport" element={<AnnualReport/>} />
        <Route path="/metaroom" element={<MetaRoom/>} />
      </Routes>
    </Layout>
  );
}
