import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'

const Container = styled.section`
	${tw`
    lg:mb-[124px]
    mb-[200px]
`}
`

const Title = styled.h2`
	:before {
		position: absolute;
		height: 2px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[291px]
    before:w-[121px] 
    before:lg:bottom-[-31px]
    before:bottom-[-21px]
    relative
    lg:font-syncopate font-montserrat
    lg:text-3xl text-2xl
    uppercase
    text-white
    lg:mb-[107px] mb-[68px]
`}
`

const Text = styled.p`
	@media (max-width: 1023px) {
		text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
		text-align: center;
	}
	${tw`
    lg:text-2xl text-xxls
    lg:pr-[80px]
    mb-[60px]
`}
`

const CardContainer = styled.ul`
@media (min-width:1024px) {
display:grid;
grid-template-columns: repeat(4,1fr);
grid-template-rows:1fr 1fr;
}
	${tw`
    mx-auto
    w-full
    xl:max-w-[1600px]
    xl:pr-[30px]
`}
`

const Card = styled.li`
	@media (max-width: 1023px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	${tw`
   xl:w-[100%]
   w-full
   z-10
`}
 @media (min-width:1000px) {
	 img {
	 	max-height:228px;
	 	margin:0 auto 20px;
	 }
 }
`

const Image = styled.img`
	${tw`
    z-10
`}
`

const CardTitle = styled.h3`
	@media (max-width: 1023px) {
		:before {
			left: 50%;
			transform: translateX(-50%);
		}
	}
	@media (min-width: 1024px) {
		:before {
			left: 43px;
		}
		white-space:nowrap;
	}
	:before {
		position: absolute;
		bottom: -15px;
		width: 185px;
		height: 2px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    relative
    lg:mb-[40px] mb-[32px]
    lg:pl-[43px]
    font-medium
    font-montserrat
    text-center lg:text-left
    text-xl
    uppercase
`}
`

const CardDescr = styled.p<{
	TitlePost?: boolean
}>`	:last-child {
 margin-top:20px
}
	${tw`
    lg:pl-[43px]
    lg:pr-[50px]
    font-montserrat
    font-light
    text-center 
	 
	  
    lg:text-left
`}
	${({ TitlePost }) =>
		TitlePost
			? tw`mb-[22px] lg:text-xl text-xxlsm uppercase`
			: tw`lg:text-lg text-xl text-center leading-6 lg:leading-5  pl-[57px] pr-[51px]`}
`

export const Team: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<Title>Team</Title>
				<Text>
					The core team includes innovators, IT and marketing specialists. These
					are creative and persistent people who are able to change the world
					for the better and do it every day.
				</Text>
			</Wrapper>
			<CardContainer>
				<Card>
					<Image src='assets/images/budha_one.png' alt='' />
					<CardTitle>Co-founder</CardTitle>
					<CardDescr TitlePost>Riazzagi</CardDescr>
					<CardDescr>
						Conscientious and decent person, resourceful engineer, skilled orator and motivator,
						goal-oriented and tenacious achiever,
						smart investor, esoteric and reality-transfer person, entrepreneur and co-founder of Lumeta.
					</CardDescr>
				</Card>
				<Card>
					<Image src='assets/images/budha_two.png' alt='' />
					<CardTitle>Co-founder</CardTitle>
					<CardDescr TitlePost>STELUM</CardDescr>
					<CardDescr>
						Strengths: Entrepreneurship, communication skills, responsibility, creativity, leadership qualities.
					</CardDescr>
					<CardDescr>
						Experience: Running own business since 2011, investment activity since 2019. Creation and development of a dozen brands from scratch.
					</CardDescr>
				</Card>
				<Card>
					<Image src='assets/images/budha_four.png' alt='' />
					<CardTitle>Concept Manager</CardTitle>
					<CardDescr TitlePost>Solarty</CardDescr>
					<CardDescr>
						Strengths: Innovative abilities, conceptual and analytical thinking, reflexivity, sensitivity to aesthetics, thinking outside the box, communicating knowledge in plain language. Ability for personal and business-oriented coaching. (visionary)
					</CardDescr>
				</Card>
				<Card>
					<Image src='assets/images/budha_three.png' alt='' />
					<CardTitle>Project manager</CardTitle>
					<CardDescr TitlePost>BOBA</CardDescr>
					<CardDescr>
						Strengths: Organizational skills, communication skills, responsibility, creativity, process optimization.
					</CardDescr>
					<CardDescr>
						Experience: Fullstack developer and IT-Consultant since 2017,project management since 2019, investment activities since 2020.
					</CardDescr>
				</Card>
				<Card>
					<Image src='assets/images/budha_five.png' alt='' />
					<CardTitle>DESIGNER</CardTitle>
					<CardDescr TitlePost>LIZER Ti</CardDescr>
					<CardDescr>
						"Responsible" for the creative component of the project. Work on website design, advertising banners, business cards, printing
						Experience of more than 3 years of design and engineering
					</CardDescr>
				</Card>
				<Card>
					<Image src='assets/images/budha_six.png' alt='' />
					<CardTitle>TECH LEAD</CardTitle>
					<CardDescr TitlePost>brassy</CardDescr>
					<CardDescr>
						Organizational skills, communication skills, responsibility,
						creativity, process optimization.
					</CardDescr>
					<CardDescr>
						Fullstack developer and IT-Consultant since 2017. Project Management
						since 2019. Investment activities since 2020.
					</CardDescr>
				</Card>
				<Card>
					<Image src='assets/images/budha_seven.png' alt='' />
					<CardTitle>CONTENT SPECIALIST</CardTitle>
					<CardDescr TitlePost>GRASSY</CardDescr>
					<CardDescr>
						Analytical skills, critical thinking, creativity, developed soft skills.
					</CardDescr>
					<CardDescr>
						Analytic and trader in the International Company, high-degree in economics, investment managing, content manager in crypto-projects
					</CardDescr>
				</Card>
				<Card>
					<Image src='assets/images/budha_eight.png' alt='' />
					<CardTitle>WEB 3 SPECIALIST</CardTitle>
					<CardDescr TitlePost>woody</CardDescr>
					<CardDescr>
						Smart contract developer specializing in DeFi technologies with more than one year of commercial development experience
					</CardDescr>
				</Card>
			</CardContainer>
		</Container>
	)
}
