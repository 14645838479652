import styled from 'styled-components';
import tw from 'twin.macro';
import {useState} from "react";


const Container = styled.div`
${tw`
    display[block]
    2md:display[none]
    max-w-[900px]
    mx-[auto]
    w-[100%]
    relative
`}
`;
const TitleRoad = styled.div`
${tw`
     relative
     text-[40px]
    font-bold
    text-center
    mr-[-4px]
    w-[180px] ml-[auto] sm:mr-[15px] flex
`}
img {
    z-index:2;
    margin:0 auto;
    cursor:pointer;
    position:relative;
}
@media (max-width:520px) {
img{width:63px}}`
const ItemsRoad = styled.div`
${tw`
    
`}
`
const ItemRoad = styled.div`
${tw`
    mt-[60px]
    relative
`}
ul {
padding-left: 95px;
list-style-type:disc ;
}
li {
font-weight: 400;
font-size: 18px;
line-height: 188.5%;
text-transform:uppercase;
max-width: 470px;
}
@media (max-width:520px) {
    ul {
        max-width: 278px;
    }
}
`
const ItemRoadTwo = styled.div`
${tw`
    mt-[60px]
    relative
`}
ul {
padding-left: 24px;
list-style-type:disc ;
max-width: 464px;
}
li {
font-weight: 400;
font-size: 18px;
line-height: 188.5%;
text-transform:uppercase;
}
@media (max-width:525px) {
li { font-size: 12px;}
}
ul {
max-width: 278px;
}
`
const ItemRoadInfo = styled.div`
${tw`
flex
items-center
`}
h4 {
font-weight: 400;
font-size: 24px;
line-height: 150%;
text-transform:uppercase;
position: relative;
top: -38px;
}
@media (max-width:905px) {
h4 {order:1;}}
`
const ItemRoadInfoTwo = styled.div`
${tw`
flex
items-center
`}
h4 {
font-weight: 400;
font-size: 24px;
line-height: 150%;
text-transform:uppercase;
position: relative;
top: -38px;
margin-right:90px
}`
const ItemRoadImg = styled.div`
span {
    position: absolute; 
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    z-index: 1;
    height:3px;
    left:52px;
    top:46.8%;
    transform:translate(0,-50%)
}

${tw`
    relative
    2md:order-2
`}

p {
font-weight: 700;
font-size: 20px;
line-height: 150%;
text-transform:uppercase;
position:absolute;
top:46.8%;
    transform:translate(0,-50%);
    left:-74px;
}
@media (max-width:905px) {
span {
    position: absolute; 
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    z-index: 1;
    height:3px;
    left:auto;
    right:60px;
    top:46.8%;
    transform:translate(0,-50%)
}
    p  {
    left:auto;
    right: -74px;
    }
    img {transform:rotate(62deg);}
}
img {
width:95px;
position:relative;
z-index: 2;
}
`
const ItemRoadImgTwo = styled.div`
span {
    position: absolute; 
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    z-index: 1;
    height:3px;
    right:52px;
    top:46.8%;
    transform:translate(0,-50%)
}

${tw`
    relative
`}
p {
font-weight: 700;
font-size: 20px;
line-height: 150%;
text-transform:uppercase;
position:absolute;
top:10%;
    transform:translate(0,-50%);
    right:64px;
}
img {
width:95px;
position:relative;
z-index: 2;
}
`
const WrapCenter = styled.div `
${tw`
    w-[3px]
    h-[2500px]
    absolute
    left-[77%] top-[9px]
`}
z-index:1;
transform:translate(-50%,0);
background:#FFFFFF;
`


interface myProp {
    back:any,
}
export const ItemMob: React.FC<myProp> = (props) => {
    const [roadOne,setRoadOne] = useState(true)
    const [roadTwo,setRoadTwo] = useState(false)
    const [roadThree,setRoadThree] = useState(false)

    return (
        <Container>
            <TitleRoad >
                2022
                <img onClick={ () =>  {setRoadOne(!roadOne);props.back(roadOne)}}  src={roadOne?"assets/vector/arrow-bottom.svg":"assets/vector/arrow-down.svg"} alt=""/>
                <WrapCenter className={ roadOne? 'hidden':'block'}>

                </WrapCenter>
            </TitleRoad>
            <ItemsRoad className={ roadOne? 'hidden':'block'}>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q1 REPORT   </h4>
                        <ItemRoadImgTwo>
                            <p>q1/2022</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[72vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Teambuilding</li>
                        <li>Philosophy & Brainstorming</li>
                        <li>Building a Road Map</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q2 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q2/2022</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[72vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Creating a marketing plan</li>
                        <li>Design creation for NFT SENSAY collection</li>
                        <li>Creation of a financial plan</li>
                        <li>Elaboration public roadmap</li>
                        <li>Creation logo of NFT SENSAY and Lumeta</li>
                        <li>Company branding</li>
                        <li>Generation of 10k NFT SENSAY collection</li>
                        <li>Development of a smart contract for NFT SENSAY</li>
                        <li>Maintaining social networks</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo  className={'max-w-[333px] ml-[auto] left-[17px]'}>
                    <ItemRoadInfoTwo>
                        <h4>Q3 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q3/2022</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[68vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Creating a White Paper for NFT SENSAY</li>
                        <li>Creation of the NFT SENSAY presentation</li>
                        <li>Creating a plan for crowdfunding</li>
                        <li>Creating a general NFT SENSAY video in 5 languages</li>
                        <li>Website development for NFT SENSAY in 5 languages</li>
                        <li>Website development for Lumeta</li>
                        <li>Acquisition of a storage facility for company expansion</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo  className={'max-w-[333px] ml-[auto] left-[17px'}>
                    <ItemRoadInfoTwo>
                        <h4>Q4 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q4/2022</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[73vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Starting to develop a smart contract for a token</li>
                        <li>Starting to design the App - Meditate to Earn</li>
                        <li>Starting to develop a 3D design of Metaroom</li>
                        <li>Business card printing NFT SENSAY / Lumeta</li>
                        <li>Creating the Lumeta Annual Report 2022</li>
                    </ul>
                </ItemRoadTwo>
            </ItemsRoad>

            <TitleRoad className={'relative mt-[100px]'} >
                2023
                <img onClick={ () =>  {setRoadTwo(!roadTwo)}}  src={roadTwo?"assets/vector/arrow-bottom.svg":"assets/vector/arrow-down.svg"} alt=""/>
                <WrapCenter className={ roadTwo? 'hidden':'block'}>

                </WrapCenter>
            </TitleRoad>
            <ItemsRoad className={ roadTwo? 'hidden':'block'}>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q1 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q1/2023</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[59vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Creating and deploying a smart contract for NFT SENSAY</li>
                        <li>Creating a White Paper for Lumeta</li>
                        <li>Creating a functional model App - Meditate to Earn</li>
                        <li>Starting to develop Metaroom based on babylon.js</li>
                        <li>Creating product documentation for investors</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q2 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q2/2023</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[77vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Resolving website operational issues</li>
                        <li>Upload demo App-M2E to Play Store</li>
                        <li>Design creation of a Dashboard on the Lumeta website</li>
                        <li>Creating a 3D drone design for Metaroom</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q3 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q3/2023</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[72vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Uploading demo Metaroom to the website</li>
                        <li>Dashboard programming on the Lumeta website</li>
                        <li>Creating a pitch deck and business plan</li>
                        <li>Attending forums and searching for investors</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q4 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q4/2023</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[77vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Agreement on attracting investments</li>
                        <li>Company registration in Dubai</li>
                        <li>Development NFT Collections (Real Estate/Art)</li>
                        <li>Starting to develop a system token</li>
                        <li>Launch of a marketing program for NFT (Real Estate/Art)</li>
                        <li>Official release of the NFT (Real Estate/Art)</li>
                    </ul>
                </ItemRoadTwo>
            </ItemsRoad>

            <TitleRoad className={'relative mt-[100px]'} >
                2024
                <img onClick={ () =>  {setRoadThree(!roadThree)}}  src={roadThree?"assets/vector/arrow-bottom.svg":"assets/vector/arrow-down.svg"} alt=""/>
                <WrapCenter className={ roadThree? 'hidden':'block'}>

                </WrapCenter>
            </TitleRoad>
            <ItemsRoad className={ roadThree? 'hidden':'block'}>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q1 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q1/2024</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[59vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Development of Metaroom</li>
                        <li>Launch of a marketing program for NFT SENSAY</li>
                        <li>Launch of a marketing program for a Token</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q2 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q2/2024</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[77vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Official release of the NFT SENSAY collection</li>
                        <li>ICO of Token</li>
                        <li>Tokenization of Real Estate via Token</li>
                        <li>Development of App on Android / iOS </li>
                        <li>Starting to develop a Metacity </li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q3 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q3/2024</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[72vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Development of trading plattfrom on Website Lumeta</li>
                        <li>Development of SWAP</li>
                    </ul>
                </ItemRoadTwo>
                <ItemRoadTwo className={'max-w-[333px] ml-[auto] left-[17px]'} >
                    <ItemRoadInfoTwo>
                        <h4>Q4 REPORT  </h4>
                        <ItemRoadImgTwo>
                            <p>q4/2024</p>
                            <img src="assets/vector/road-left.svg" alt=""/>
                            <span className='w-[77vw]'></span>
                        </ItemRoadImgTwo>
                    </ItemRoadInfoTwo>
                    <ul>
                        <li>Development of Marketplace</li>
                        <li>Development of NFT Gallery</li>
                        <li>Development of AI for Metaverse</li>
                        <li>Design creation for NFT #2 collection</li>
                        <li>Creating and deploying a smart contract for NFT #2</li>
                    </ul>
                </ItemRoadTwo>
            </ItemsRoad>
        </Container>
    );
};