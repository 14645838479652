import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/montserrat';
import '@fontsource/syncopate';
import './styles/globals.css';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from "./components/ScrollTop";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <ScrollToTop/>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
