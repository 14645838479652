import styled from 'styled-components'
import tw from 'twin.macro'
import { PostTitle } from '../UI/text'
import { Wrapper } from '../wrapper'

const Container = styled.section`
	${tw`
    lg:mb-[149px]
    mb-[67px]
    lg:pl-[73px]
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-2xl
    
    text-xxls text-center lg:text-left
`}
`

const List = styled.ul`
	${tw`
    mb-[90px]
    lg:mb-[100px]
`}
`

const Item = styled.li`
	${tw`
    flex items-center lg:flex-row flex-col
    lg:w-[100%]
    text-center lg:text-left
`}
	
	@media(min-width: 1024px) {
		img {
			margin-right: 41px;
		}
	}
	@media (max-width: 1023px) {
		img {
			margin-bottom: 28px;
		}
	}
`

export const Company: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<PostTitle className='mb-[84px] before:w-[682px]'>
					The company's credo
				</PostTitle>
				<List>
					<Item className='mb-[52px] lg:mb-[35px]'>
							<img src='assets/vector/company-1.svg' alt='' />
						<Text>Organization and structuring</Text>
					</Item>
					<Item className='mb-[52px] lg:mb-[35px]'>
							<img src='assets/vector/company-2.svg' alt='' />
						<Text>Full involvement in the idea </Text>
					</Item>
					<Item className='mb-[52px] lg:mb-[35px]'>
							<img src='assets/vector/company-3.svg' alt='' />
						<Text>Full involvement in the idea </Text>
					</Item>
					<Item className='mb-[52px] lg:mb-[35px]'>
							<img src='assets/vector/company-4.svg' alt='' />
						<Text>Quality and integrity</Text>
					</Item>
					<Item>
							<img src='assets/vector/company-5.svg' alt='' />
						<Text>Openness to new things and transparency of processes</Text>
					</Item>
				</List>
				<Text className='mb-[30px] lg:pr-[100px] lg:leading-6 leading-6'>
					Your reality is capable of more! The project of a Metaverse with
					limitless possibilities, originated in Europe, embodies all the hidden
					possibilities, dreams and aspirations of every person. This project
					contains the most promising modern trends in the world of crypto
					technologies, such as NFT, the development of a token, our own
					blockchain and, of course, the crown of our plans - a Metaverse with
					unique options and extensions for all users
				</Text>
				<Text className='mb-[45px] lg:pr-[120px]  lg:leading-6 leading-6'>
					The most incredible dreams and ideas can be realized inside a new
					metaverse with flexible functionality. Do you want to master new
					skills - learn languages, travel around the world, find like-minded
					people, earn money while enjoying spending time in the reality of your
					dreams? - Then it's time to take a step towards the future, the future
					of better dreams. In the hands of each user unique opportunities where
					the boundaries exist only in your imagination.
				</Text>
				<Text className='lg:pr-[130px]  lg:leading-6 leading-6'>
					Creativity and determination coupled with imagination is the key to
					our work and the success of the Lumeta project. Our virtual reality
					will help you to be one step ahead. After all, the future belongs to
					creative and creative technologies!"
				</Text>
			</Wrapper>
		</Container>
	)
}
