import styled from 'styled-components'
import tw from 'twin.macro'

// Первая секция главной

const Container = styled.section`
	${tw`
    w-screen
    lg:mb-[100px]
    mb-[55px]
    pt-[90px]
`}
`

// Внутренний контейнер

const Content = styled.div`
	${tw`
    lg:flex lg:justify-center lg:items-center
    mx-auto
    px-[30px]
    lg:px-[initial]
    lg:max-w-[1500px]
    lg:pr-[130px]
`}
`

// Треугольник

const Triangle = styled.img<{
	treuMob?: boolean
}>`
	${({ treuMob }) =>
		treuMob
			? tw`lg:display[none] top-[85px] left-0 absolute`
			: tw`lg:mt-[auto]
    display[none] lg:block`}
    max-width:600px;
    @media (max-width:1024px){
    top: -244px;
	left: -37px;
    }
`

// Контейнер под текст

const TextContainer = styled.div`
	${tw`
    text-white
    lg:w-[622px]
`}
`

// Контейнер титула

const TitleContainer = styled.div`
	${tw`
    flex justify-center mb-[40px]
    
    lg:block md:justify-center lg:mb-[initial]
`}
 @media (max-width:1024px){
    position:relative}
`

// Титле

const Title = styled.h1`
	:before {
		position: absolute;
		bottom: -11px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:left-0
    before:lg:w-[285px]
    before:lg:h-[3px]
    before:left-[-55px]
    before:w-[230px]
    before:h-[2px]
    relative
    font-syncopate
    font-normal
    lg:text-5xl
    text-2xl
	lg:left-[auto]
	left-24
    uppercase
    mb-[30px]
`}
`

// Описание
// lg:capitalize
const Text = styled.p`
	${tw`
    font-montserrat
    font-normal
    lg:text-lg
    text-xxlsm
    text-center
    lg:text-left
`}
`

export const WeAre: React.FC = () => {
	return (
		<Container>
			<Content>
				<Triangle src='./assets/images/triangle.png' alt='' />
				<TextContainer>
					<TitleContainer>
						<Triangle treuMob src='./assets/images/triangle_mob.png' alt='' />
						<Title>We are</Title>
					</TitleContainer>
					<Text>
                    We are a young and energetic group of creative minds who want to bring more light into our world. On the threshold of creating something unique and unimaginable, we took modern  
blockchain technology paired with NFT digital art, added innovation and opportunities in the form of passive income and the realization of creativity for each of us, mixed everything  
together and created a company called Lumeta. Lumeta is our creation, representing a new  
era of digital technology in the metaspace.
					</Text>
				</TextContainer>
			</Content>
		</Container>
	)
}
