import styled from "styled-components";
import tw from "twin.macro";

// Обертка

export const Wrapper = styled.div`
  ${tw`
  mx-auto
  lg:max-w-[1440px]
  w-screen
  px-[30px]
  lg:px-[100px]
`}
`;
