import { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'
import { Ask } from './ask'

const Container = styled.section`
	${tw`
   lg:mt-[162px]
   mt-[85px]
   lg:mb-[250px]
   mb-[170px]
`}
`

const Title = styled.h1`
	:before {
		position: absolute;
		left: 50%;
		height: 2px;
		transform: translateX(-50%);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[291px]
    before:w-[195px] 
    before:lg:bottom-[-28px]
    before:bottom-[-20px]
    relative
    font-syncopate
    text-white
    font-normal
    lg:text-5xl
    text-4xl
    uppercase
    text-center
    lg:mb-[128px]
    mb-[73px]
`}
`

const List = styled.ul`
	${tw`
    lg:flex lg:flex-col lg:items-center
`}
`

const Item = styled.li<{
	activeItem?: boolean
}>`
	${tw`
    lg:rounded-[20px]
    rounded-[10px]
    mb-[31px] 
    bg-white
    lg:w-[80%]
`}
cursor:pointer;
	@media(min-width: 1024px) {
		${({ activeItem }) =>
			activeItem && 'box-shadow: 0px 8px 50px rgba(255, 255, 255, 0.25);'}
	}
	@media (max-width: 1023px) {
		box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);
	}
`

//lg:p-[18px 70px 19px 80px]
//lg:p-[10px 70px 10px 50px]
const QuestContainer = styled.div<{
	activeTitle?: boolean
}>`
	border-radius: 20px;
	@media (min-width: 1024px) {
		${({ activeTitle }) =>
			activeTitle
				? 'box-shadow: 0px 8px 28px rgba(226, 226, 226, 0.25);'
				: 'box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);'}
	}
	@media (max-width: 1023px) {
		${({ activeTitle }) =>
			activeTitle && 'box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);'}
	}
	${tw`
    lg:p-[18px 70px 19px 80px]
    p-[16px 16px 16px 22px]
    flex justify-between items-center
`}
`

const Text = styled.p`
	${tw`
    lg:text-xl
    text-lgsm
    font-normal
    font-montserrat
    text-faq
    cursor-pointer
`}
`

const AskContainer = styled.div<{
	active?: boolean
}>`
	animation-name: open;
	animation-duration: 0.8s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	animation-delay: 0s;
	@keyframes open {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	${({ active }) => (active ? tw`block` : tw`display[none]`)}
	${tw`lg:p-[30px 80px 41px] p-[19px 37px 19px 22px]`}
`

const Qwerty = styled.p`
	${tw`
    font-montserrat
    font-normal
    lg:text-xl
    text-lgs
    tracking-negative
    text-faq-ask
`}
`

const Icon = styled.div`
	box-shadow: 0px 4px 8px rgba(225, 225, 225, 0.25);
	${tw`
    bg-white w-[55px] h-[55px]
    flex justify-center items-center
    rounded-full
    cursor-pointer
`}
`

export const Questions: React.FC = () => {
	const [curOpen, setCurOpen] = useState<any>([])
	const Handler = (index: number) => {
		const sectionIndex = curOpen.findIndex((item: any) => item === index)
		let newSelected: any[] = []

		if (sectionIndex !== -1) {
			curOpen.splice(sectionIndex, 1)
			newSelected = [...curOpen]
		} else {
			newSelected = [...curOpen, index]
		}
		setCurOpen(newSelected)
	}

	return (
		<Container>
			<Wrapper>
				<Title>Faq</Title>
				<List>
					{Ask.map((item, index) => {
						return (
							<Item activeItem={index + 1 === curOpen} key={index}>
								<QuestContainer onClick={() => Handler(index + 1)}  activeTitle={index + 1 === curOpen}>
									<Text >{item.title}</Text>
									<Icon >
										<img src='assets/vector/chevron_down.svg' alt='' />
									</Icon>
								</QuestContainer>
								<AskContainer active={curOpen.includes(index + 1)}>
									<Qwerty>{item.descr}</Qwerty>
								</AskContainer>
							</Item>
						)
					})}
				</List>
			</Wrapper>
		</Container>
	)
}
