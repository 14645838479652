import styled from 'styled-components'
import tw from 'twin.macro'
import React, {useEffect, useRef, useState} from 'react';

// Кнопка запуска
const ImagePlay = styled.img`
	${tw`
  fixed bottom-[16px] right-[40px]
  cursor-pointer
`}
@media (max-width:500px) {
display:none;
}
`
// Кнопка остановки
const ImageStop = styled.img<{
    active?: boolean
}>`
	${tw`
  
  cursor-pointer
  display[none]
		`}
z-index:1000;
	@media (max-width: 500px){
	display:none!important;
	}

${({ active }) =>
    active
        ? tw`fixed bottom-[61px] right-[85px]`
        : tw`fixed bottom-[61px] right-[85px]`}
		
`
interface myProp {
    check:boolean,
    stop:boolean,
}
export const MusicPlayer:  React.FC<myProp> = (props) => {
    const [play,setPlay] = useState(false);
    const audioRef = useRef(new Audio('./assets/audio/meditacia.mp3'));
    audioRef.current.loop = true;
    function playMusic () {
        audioRef.current.volume = 0.25;
        setPlay(!play);
        play ? audioRef.current.pause() : audioRef.current.play();
    }

    if (props.stop === true) {
        audioRef.current.volume = 0;
    }

    if (props.stop === false && !audioRef.paused ) {
        audioRef.current.volume = 0.25;
    }

    const [btn, setBtn] = useState<boolean>(false)

    const btnScroll = () => {
        const currentScrollPos = window.pageYOffset
        currentScrollPos > 100 ? setBtn(true) : setBtn(false);
    }
    useEffect(() => {
        window.addEventListener('scroll', btnScroll)
        return () => {
            window.removeEventListener('scroll', btnScroll)
        }
    }, [btnScroll])

return (
    <>
        {
            props.stop
            ? '' :
            <>
            <ImagePlay onClick={ playMusic} className={ play ? 'audio-stop' : ' '}
                   src={props.check ? 'assets/vector/play_new.svg' : 'assets/vector/play_video.svg'} alt='' />
            <ImageStop onClick={ playMusic} active={btn} className={ play ? 'audio-active' : ' '}
                   src={props.check ? 'assets/vector/stop_video-blue.svg' : 'assets/vector/stop_video.svg'} alt='' />
            </>
        }
    </>
)
}