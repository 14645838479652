import styled from 'styled-components'
import tw from 'twin.macro'
import {Wrapper} from "../wrapper";
import {Title} from "../UI/text";
import {ButtonCopied} from "../UI/btn";

const Container = styled.section`
	${tw`
    mt-[150px]
    mb-[140px]
    text-center
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-xl text-xxls
    lg:mb-[96px] mb-[38px] text-center
    lg:px-[60px] px-[20px]
    lg:max-w-[500px]
    lg:mx-[auto]
    uppercase
`}
`

const Btn = styled.button`
	:before {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.1) 100%
		);
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
	cursor: pointer;
	${tw`
    before:lg:rounded-[20px]
    before:lg:p-[3px]
    before:rounded-[10px]
    before:p-[2px]
    relative
    lg:p-[21px 28px]
    p-[21px 28px]
    lg:text-xl
    font-normal
    text-xxls
    font-syncopate
    uppercase
   
`}
`
const WrapperCard = styled.div`
${tw`
    grid text-center lg:grid-cols-3 
    lg:mb-[140px]
`}
`
const Card = styled.div`
${tw`

`}
img {
    margin:0 auto 20px;
    max-width: 60%;
}
`
const TitleCard = styled.p`
${tw`
    text-xl uppercase font-semibold
    mb-[10px]
`}
`
const TextCard = styled.p`
font-size: 1.125rem;
line-height: 1.75rem;
${tw`
    font-light
    mb-[25px]
`}
`
const WrapperResume = styled.div`
:before {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.1) 100%
		);
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
	@media (max-width:1024px) {
	:before {
	padding:1px;
	height:1px;
	left:50%;
	transform:translate(-50%,0);
	max-width:[199px];
	}
	}
${tw`
     before:lg:rounded-[20px]
    before:lg:p-[3px]
    before:rounded-[10px]
    before:p-[1px]
    relative
    lg:p-[55px 92px]
    p-[30px 0 10px]
    mt-[70px]
`}
h2 { ${tw`
text-xl
lg:mb-[50px]
mb-[30px]
`} 
}
p { ${tw`
text-xl
`}
}
`

export const CareersHero: React.FC = () => {
    return (
        <Container>
            <Wrapper>
                <Title className='mb-[93px] lg:mb-[60px]'>CAREERS</Title>
                <Text>
                    At the moment we are looking for the following specialists:
                </Text>
                <WrapperCard>
                    <Card>
                        <img className='imgCareer' src="assets/vector/careersCard.svg" alt=""/>
                        <TitleCard>Programmer</TitleCard>
                        <TextCard>Requirements: Web3, Next.js, <br/>React, Node.js</TextCard>
                        <Btn onClick={() => window.location.href = 'mailto:business@lumeta.io'}>Send resume</Btn>
                    </Card>
                    <Card>
                        <img className='imgCareer' src="assets/vector/careersCard.svg" alt=""/>
                        <TitleCard>Marketing specialist</TitleCard>
                        <TextCard>Requirements: Proactive, Creativity, <br/>Communication</TextCard>
                        <Btn onClick={() => window.location.href = 'mailto:business@lumeta.io'}>Send resume</Btn>
                    </Card>
                    <Card>
                        <img className='imgCareer' src="assets/vector/careersCard.svg" alt=""/>
                        <TitleCard>Programmer</TitleCard>
                        <TextCard>Requirements: Web3, Node.js, Blockhain, Solidity</TextCard>
                        <Btn onClick={() => window.location.href = 'mailto:business@lumeta.io'}>Send resume</Btn>
                    </Card>
                </WrapperCard>
                <WrapperResume>
                    <h2>If none of the vacancies fit your description,
                        but you have something to offer us - send us your resume by email</h2>
                    <ButtonCopied name='business@lumeta.io'/>
                    <p className='lg:mt-[26px] mt-[20px]'>Consider any suggestion!</p>
                </WrapperResume>
            </Wrapper>
        </Container>
    )
}