import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'

const Container = styled.section`
	${tw`
    mt-[150px]
    mb-[140px]
    text-center
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-lg text-xxls
    lg:mb-[96px] mb-[38px] text-center
    lg:px-[60px] px-[20px]
`}
`

const Btn = styled.button`
	:before {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.1) 100%
		);
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
	cursor: default;
	${tw`
    before:lg:rounded-[20px]
    before:lg:p-[3px]
    before:rounded-[10px]
    before:p-[2px]
    relative
    lg:p-[9px 28px]
    p-[18px 38px]
    lg:text-2xl
    font-normal
    text-xxls
    font-syncopate
    uppercase
   
`}
`

export const ShopHero: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<Title className='mb-[93px] lg:mb-[122px]'>Shop</Title>
				<Btn>In development</Btn>
			</Wrapper>
		</Container>
	)
}
