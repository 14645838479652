import { RoadmapHero } from "../../components/roadmapHero";


export default function Roadmap() {
  return (
    <>
      <RoadmapHero />
    </>
  );
}
