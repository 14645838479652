import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'
import { ItemDesc } from './itemDesc'
import { ItemMob } from './itemMob'
import {RoadItem} from "./roadItem";
import {useState} from "react";

// Контейнер секции

const Container = styled.section`
	${tw`
	relative
    lg:mt-[189px]
    mt-[80px]
    lg:pb-[264px]
    pb-[260px]
`}
overflow:hidden;
`
const ImageBack = styled.img`
${tw`
absolute
right-[44%]
h-[4527px]
w-[57%]

`}
`
const Title = styled.h1`
	:before {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[567px]
    before:lg:h-[3px]
    before:h-[2px]
    before:w-[195px] 
    before:lg:bottom-[-32px]
    before:bottom-[-20px]
    relative
    font-syncopate
    text-center
    lg:text-4xl
    text-3xl
    uppercase
    text-white
    lg:mb-[200px]
    mb-[100px]
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-2xl
    text-xxls
    font-normal
    text-center
`}
`
const TextContainer = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
`}
`
const ImageBackLeft = styled.img `
${tw`
absolute
left-[0]
h-[101%]
`}
`
const ImageBackLeft2 = styled.img `
background-repeat: repeat-x;
height: 100%;
${tw`
absolute
left-[0]
top-[5000]
`}
`

const ImageBackRight = styled.img `
${tw`
absolute
right-[0]
hidden
2md:block
h-[100%]
`}
`
export const RoadmapHero: React.FC = () => {
	const [test,setTest] = useState(true)
	return (
		<Container>
			{/*<ImageBack className={test? '':'bottom-0'} src='assets/vector/tester.png' />*/}
			<ImageBackLeft src='assets/vector/road-l.svg'/>
            {/* <ImageBackLeft2 src='assets/vector/road-l.svg'/> */}
			<ImageBackRight src='assets/vector/road-r.svg'/>
			<Wrapper>
				<TextContainer>
					<Title>Road map lumeta</Title>
					<Text className='mb-[15px] lg:mb-[30px]'>
						Integration of the ecosystem according to the type of planetary
						system with the possibility of interaction with project planets.
					</Text>
					<Text className='mb-[31px] lg:mb-[62px]'>
						The NET SUNSAY project originates, which is an integral part of a
						large and interacting ecosystem, the Lumeta ecosystem. Each
						subsequent project is linked to the previous one by introducing
						payment systems in the form of a token and its own cryptocurrency
						based on blockchain technologies.
					</Text>
				</TextContainer>
			</Wrapper>
			<ItemMob back={setTest}/>
			<RoadItem back={setTest}/>
		</Container>
	)
}
