import {BlogHero} from "../../components/BlogHero";
import {BlogSocial} from "../../components/BlogSocial";


export default function Blog() {
    return (
        <>
            <BlogSocial/>
            <BlogHero/>
        </>
    );
}