import styled from "styled-components";
import tw from "twin.macro";
import { Footer } from "../footer";
import { Navbar } from "../navbar";

type LayoutProps = {
  children: React.ReactNode;
};

const PageContainer = styled.div`
${tw`
  flex
  flex-col
  justify-center
  w-screen
  font-montserrat
  font-normal
  text-white
  min-h-screen
  items-center
  overflow-x-hidden
`}
`;

const Mains = styled.main`
@media(max-width: 1023px) {
  background: linear-gradient(179.74deg, #42809F -7.41%, #96CFDC 31.77%);
}
@media(min-width: 1024px) {
  background: linear-gradient(83.57deg, #42809F 7.04%, #96CFDC 76.77%, #97AFC4 108.86%);
}
${tw`
  w-screen
  flex-auto
  mb-[53px]
`}
`;

export default function Layout({
  children,
}: LayoutProps) {

  return (
    <>
      <Navbar />
    <PageContainer>
      <Mains>
        {children}
      </Mains>
      <Footer />
    </PageContainer>
    </>
  );
};
