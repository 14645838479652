import { AboutHero } from "../../components/aboutHero";
import { Company } from "../../components/company";
import { Philosophie } from "../../components/philosophie";
import { Team } from "../../components/team";



export default function About() {
  return (
    <>
      <AboutHero />
      <Philosophie />
      <Company />
      <Team />
    </>
  );
}
