import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'
import {useState} from "react";

const Container = styled.section`
	${tw`
    mt-[150px]
    mb-[140px]
    text-center
`}
`
const SubTitle = styled.h2`
	:before {
		position: absolute;
		bottom: -29px;
		width: 100%;
		height: 2px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	@media (max-width:500px) {
	:before {display:none;}
	}
	${tw`
    relative
    sm:text-2xl
    text-xl
    lg:font-medium
    font-normal
    uppercase
    inline
`}
`
const WrapperTitle = styled.div`
${tw`
sm:text-left
text-center
w-[80%]
mx-[auto]
mb-[86px]
`}
`


const List = styled.ul`
	${tw`
    lg:flex lg:flex-col lg:items-center
`}
`

const Item = styled.li<{
    activeItem?: boolean
}>`
	${tw`
    lg:rounded-[20px]
    rounded-[10px]
    mb-[31px] 
    bg-white
    lg:w-[80%]
`}
cursor:pointer;
	@media(min-width: 1024px) {
		${({ activeItem }) =>
    activeItem && 'box-shadow: 0px 8px 50px rgba(255, 255, 255, 0.25);'}
	}
	@media (max-width: 1023px) {
		box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);
	}
`

const QuestContainer = styled.div<{
    activeTitle?: boolean
}>`
	border-radius: 20px;
	@media (min-width: 1024px) {
		${({ activeTitle }) =>
    activeTitle
        ? 'box-shadow: 0px 8px 28px rgba(226, 226, 226, 0.25);'
        : 'box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);'}
	}
	@media (max-width: 1023px) {
		${({ activeTitle }) =>
    activeTitle && 'box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);'}
	}
	${tw`
    lg:p-[18px 38px 19px 31px]
    p-[16px 16px 16px 22px]
    flex justify-between items-center
`}
`

const Text = styled.p`
	${tw`
    lg:text-xl
    text-lgsm
    font-normal
    font-montserrat
    text-faq
    cursor-pointer
    mr-[auto]
    uppercase
`}
@media (max-width:500px) {
text-align:left;}
`

const AskContainer = styled.div<{
    active?: boolean
}>`
	animation-name: open;
	animation-duration: 0.8s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	animation-delay: 0s;
	@keyframes open {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	${({ active }) => (active ? tw`block` : tw`display[none]`)}
	${tw`lg:p-[30px 32px 41px] p-[19px 19px 22px]`}
`


const Icon = styled.div`
	box-shadow: 0px 4px 8px rgba(225, 225, 225, 0.25);
	${tw`
    bg-white w-[55px] h-[55px]
    flex justify-center items-center
    rounded-full
    cursor-pointer
`}
`
const ImgTitle = styled.img`
${tw`
w-[60px]
mr-[25px]
`}
@media (max-width:500px) {
width:45px}
`
const TextArea = styled.textarea`
border: 1px solid #DBDBDB;
border-radius: 20px;
resize: none;
color:black;
${tw`
focus:outline-none
p-[20px]
w-[100%]
min-h-[229px]
`}
`
const Btn = styled.button`
background: linear-gradient(110.02deg, #D3E1E8 -2.46%, #96CFDC 59.07%, #97AFC4 109.96%);
border-radius: 10px;
${tw`
uppercase flex justify-center items-center
sm:w-[111px] sm:h-[39px]
w-[179px] h-[47px]
ml-[auto] mt-[20px]
`}`
export const FeedbackHero: React.FC = () => {
    const [fedOpenOne, setFedOpenOne] = useState<boolean>(false)
    const [fedOpenTwo, setFedOpenTwo] = useState<boolean>(false)
    const [fedOpenThree, setFedOpenThree] = useState<boolean>(false)

    const [commentText1,setCommentText1] = useState<string>("")
    const [commentText2,setCommentText2] = useState<string>("")
    const [commentText3,setCommentText3] = useState<string>("")

    const onSubmit = async (commentText: string, subject: string) => {
        if (commentText.length<=3) {
            alert('Message too small.');
            return false;
        }
        console.log(commentText);

        const endpointUrl = 'https://lumeta.io/send/index.php';

        await fetch(endpointUrl, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                subject: subject,
                message: commentText
            }),
        })
        .then((response) => {
            if (response.status !== 200) {
                throw new Error(`${response.statusText} (${response.status})`);
            }
            console.log(1, response);

            return response.json();
        })
        .then(data => {
            console.log(2, data);
        })
        .catch((err) => {
            console.log(4,err.toString());
        });

        alert('Thank you! Message sent.');
    }

    return (
        <Container>
            <Wrapper>
                <Title className='mb-[93px] lg:mb-[122px]'>Feedback</Title>
                <WrapperTitle>
                    <SubTitle>Leave us feedback for:</SubTitle>
                </WrapperTitle>
                <List>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenOne(!fedOpenOne)} >
                            <ImgTitle src='assets/vector/feedback.svg' alt='' />
                            <Text >Product Suggestion</Text>
                            <Icon >
                                <img src='assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenOne}>
                            <TextArea name="text-one" 
                            value = {commentText1}
                            onChange={e => setCommentText1(e.target.value)}
                            ></TextArea>
                            <Btn onClick={() => {onSubmit(commentText1,'Product Suggestion'); setCommentText1("")}}>to send</Btn>
                        </AskContainer>
                    </Item>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenTwo(!fedOpenTwo)} >
                            <ImgTitle src='assets/vector/feedback2.svg' alt='' />
                            <Text >Errors in writing / translation </Text>
                            <Icon >
                                <img src='assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenTwo}>
                            <TextArea name="text-two" 
                                value = {commentText2}
                                onChange={e => setCommentText2(e.target.value)}
                            ></TextArea>
                            <Btn onClick={() =>{ onSubmit(commentText2,'Errors in writing'); setCommentText2("")}}>to send</Btn>
                        </AskContainer>
                    </Item>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenThree(!fedOpenThree)} >
                            <ImgTitle src='assets/vector/feedback3.svg' alt='' />
                            <Text >Website design  </Text>
                            <Icon >
                                <img src='assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenThree}>
                            <TextArea name="text-three" 
                                value = {commentText3}
                                onChange={e => setCommentText3(e.target.value)}
                            ></TextArea>
                            <Btn onClick={() =>{ onSubmit(commentText3, 'Website design'); setCommentText3("")}}>to send</Btn>
                        </AskContainer>
                    </Item>
                </List>
            </Wrapper>
        </Container>
    )
}