import styled from 'styled-components'
import tw from 'twin.macro'

export const Title = styled.h1`
	:before {
		position: absolute;
		left: 50%;
		height: 2px;
		transform: translateX(-50%);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[291px]
    before:w-[195px] 
    before:lg:bottom-[-28px]
    before:bottom-[-20px]
    relative
    font-syncopate
    text-center
    lg:text-5xl
    text-4xl
    uppercase
    text-white
`}
`

export const PostTitle = styled.h2`
	@media (max-width: 1023px) {
		:before {
			width: 169px;
		}
	}
	:before {
		position: absolute;
		bottom: -29px;
		height: 2px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    relative
    lg:text-4xl
    text-2xl
    lg:font-medium
    font-normal
    uppercase
`}
`
