import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'

const Container = styled.section`
	${tw`
    lg:mt-[150px] mt-[80px]
    lg:mb-[207px] mb-[96px]
`}
`

const Content = styled.div`
	${tw`
    flex flex-col lg:flex-row lg:justify-between lg:items-center
    sm:w-[974px] sm:mx-[auto] sm:pl-[10px]
`}
`

const Image = styled.img<{
	mob?: boolean
}>`
	${tw`
	lg:max-w-xs
	`}
	
	${({ mob }) => (mob ? tw`lg:display[none]` : tw`display[none] lg:block`)}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-lg
    text-xxls
    font-normal
    px-[18px] lg:px-[initial]
    xl:pl-[75px]
    xl:pr-[90px]
    lg:text-left text-center
`}
`

export const InvestorsHero: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<Title className='mb-[95px] lg:mb-[101px]'>Investors</Title>
				<Content>
					<a href="assets/White Paper NFT SENSAY.pdf" target='_blank'>
						<Image src='assets/images/white_list.png' alt='' />
						<Image mob src='assets/images/white_list_mob.png' alt='' />
					</a>
					
					<Text>
                        An informational document issued by Lumeta to promote the features of solutions and products.
					</Text>
					{/* <Text>
						Integration of the ecosystem according to the type of planetary
						system with the possibility of interaction with project planets.
						<br /> The NET SENSAY project originates, which is an integral part
						of a large and interacting ecosystem, the Lumeta ecosystem.
					</Text> */}
				</Content>


				{/* <Title className='mb-[95px] lg:mb-[101px] mt-[60px] lg:mt-[20px]'>Investment Benefints</Title> */}
				{/* <br/><br/>
                <Title className='mb-[95px] lg:mb-[101px]'>Investment Benefints</Title>
				<Content>
					<a href="assets/Investment Benefits.pdf" target='_blank'>
						<Image src='assets/images/benefits.png' alt='' />
						<Image mob src='assets/images/benefits_mob.png' alt='' />
					</a>
					
					<Text>
					The financial plan reflects the strategy for the development of projects and investments for 
their implementation. The amount of individual investments in projects is varied depending 
on market relations. 
					</Text>
				</Content> */}
			</Wrapper>
		</Container>
	)
}
