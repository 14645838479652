import { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'

// Header Container
//tw
//    lg:sticky
const Container = styled.nav<{
	activeNav?: boolean
	mobNav?: boolean
}>`
	${({ mobNav }) =>
		mobNav ? tw`bg-white h-screen w-screen top-0` : tw`top-[16px]`}
	${tw`
    fixed
    lg:top-0
    lg:h-0
    z-50
`}
${({ activeNav }) =>
		activeNav
			? tw`lg:text-black lg:bg-white lg:h-[auto] lg:box-shadow[0 0 10px #000;] w-[100%]`
			: tw`lg:text-white lg:h-[auto] w-[100%]`}
`

// Список

const List = styled.ul<{
	mobActive?: boolean
}>`
	${tw`
    list-none
    lg:flex
    display[none]
    lg:justify-between
    lg:items-center
    pt-[80px]
    lg:py-[14px]
    lg:px-[25px]
`}
	${({ mobActive }) =>
		mobActive
			? tw`flex
    flex-col`
			: tw``}
`

// Кнопки навигации

const Item = styled.li<{
	notAnim?: boolean
	active?: boolean
	anim?: boolean
	mobMenu?: boolean
	logos?:boolean
}>`
	${({ mobMenu }) => mobMenu && tw`lg:display[none]`}
	${tw`
    uppercase
    font-medium
    text-lg
    duration-300  	
   	opacity-60
    mb-[20px] lg:mb-[initial]
    cursor-pointer
    relative
    ease-in
    transition-all
`}
${({ active }) => (active ?  `text-shadow: 1px 0px 12px white;opacity:1;font-weight:700;` : tw``)}
${({ notAnim }) =>
		notAnim
			? tw`lg:block display[none]`
			: `
    :hover{opacity:1;font-weight:700;text-shadow: 1px 0px 12px white;}
`}
${({ notAnim, anim }) =>
		!notAnim && anim ? tw`after:bg-white` : tw`after:bg-white`}
		${({ logos }) => (logos ?  tw`opacity-100` : tw``)}
`

// Контейнер мобильный

const MobContainer = styled.div`
	${tw`
    flex justify-between items-center lg:display[none]
    z-[99]
`}
`

// Логотип

const LogoImg = styled.img<{
	mob?: boolean
}>`
	${({ mob }) => (mob ? tw`lg:display[none]` : tw`lg:block display[none]`)}
	@media (min-width:700px) {
	opacity:1
	}
	:hover {opacity:1;}
	transition: all .2s linear;
	
`
// Бургер меню

const Burger = styled.div<{
	burg?: boolean
}>`
	:after {
		transform: ${({ burg }) => (burg ? 'rotate(45deg);' : 'rotate(0deg)')};
	}
	:before {
		transform: ${({ burg }) => (burg ? 'rotate(-45deg)' : 'rotate(0deg)')};
	}
	${({ burg }) =>
		burg
			? tw`top-[16px] after:bottom-[10px] before:top-[5px] before:bg-black after:bg-black`
			: tw`after:bottom-0 before:top-0 before:bg-white after:bg-white`}
	${tw`
    w-[28px]
    relative
    h-[17px]
    flex
    justify-center
    items-center
    duration-300
    after:duration-300
    before:duration-300
    before:content-[""]
    before:w-full
    before:h-[2px]
    before:absolute
    before:left-0
    after:w-full
    after:h-[2px]
    after:content-[""]
    after:left-0
    after:absolute
`}
`

// Вторая линия бургера

const BurgerTwo = styled.span<{
	burgTwo?: boolean
}>`
	${({ burgTwo }) => (burgTwo ? tw`opacity-0` : tw`opacity-100`)}
	${tw`
    block
    delay-300
    bg-white
    w-full
    h-[2px]
`}
`

export const Navbar: React.FC = () => {
	const location = useLocation()
	const [path, setPath] = useState(window.location.pathname)

	useEffect(() => {
		setPath(window.location.pathname)
	}, [location])

	const [visible, setVisible] = useState<boolean>(false)

	useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
    
            if (currentScrollPos > 100) {
                setVisible(true)
            } else {
                setVisible(false)
            }
        }

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [visible])

	const [open, setOpen] = useState<boolean>(false)

	const handleOpen = () => {
		setOpen(open => !open)
	}

	useEffect(() => {
		if (open === true) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.removeProperty('overflow')
		}
	}, [open])

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Container mobNav={open} activeNav={visible}>
			<Wrapper>
				<MobContainer>
					<Link to={'/'}><LogoImg mob src='assets/vector/logo_mob.svg' alt='' /></Link>
					<Burger onClick={handleOpen} burg={open}>
						<BurgerTwo burgTwo={open}></BurgerTwo>
					</Burger>
				</MobContainer>
				<List mobActive={open}>
					<Item active={path === '/' ? true : false} notAnim logos={true}>
						<Link to={'/'}>
							<LogoImg
								src={
									visible
										? './assets/vector/logo-black.svg'
										: './assets/vector/logo-hover-n.svg'
								}
								alt=''
							/>
						</Link>
					</Item>
					<Item
						mobMenu
						onClick={handleClose}
						active={path === '/' ? true : false}
					>
						<Link to={'/'}>Home</Link>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
						active={path === '/about' ? true : false}
					>
						<NavLink to={'/about'}>About</NavLink>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
						active={path === '/roadmap' ? true : false}
					>
						<Link to={'/roadmap'}>Road Map</Link>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
						active={path === '/projects' ? true : false}
					>
						<Link to={'/projects'}>Projects</Link>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
						active={path === '/investors' ? true : false}
					>
						<Link to={'/investors'}>Investors</Link>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
						active={path === '/shop' ? true : false}
					>
						<Link to={'/shop'}>Shop</Link>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
						active={path === '/metaroom' ? true : false}
					>
						<Link to={'/metaroom'}>Metaroom</Link>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
						active={path === '/faq' ? true : false}
					>
						<Link to={'/faq'}>Faq</Link>
					</Item>
					<Item
						onClick={handleClose}
						anim={visible}
					>
						<Link to={'https://dashboard.lumeta.io'}>Dashboard</Link>
					</Item>
				</List>
			</Wrapper>
		</Container>
	)
}
