import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'
import { Link, NavLink, useLocation } from 'react-router-dom'

// Контейнер для секции с отступом

const Container = styled.section`
	${tw`
    lg:mb-[135px]
    mb-[141px]
    text-center
`}
`

// Тайтл

const Title = styled.h1`
	:before {
		position: absolute;
		bottom: -25px;
		left: 50%;
		transform: translateX(-50%);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:h-[3px]
    before:h-[2px]
    before:lg:w-[450px]
    before:w-[243px] 
    relative
    font-syncopate
    lg:mb-[120px]
    mb-[50px]
    text-white
    font-normal
    lg:text-5xl
    text-4xl
    uppercase
    text-center
`}
`

// Контейнер для карточек

const CardContainer = styled.div`
	${tw`
    lg:pl-[18px] lg:pr-[3px]
    lg:mb-[139px] lg:mx-[auto]
    lg:w-[65%]
    mb-[62px]
    flex flex-col items-center
    lg:block
`}
`

// Карточка

const Card = styled.div<{
	lineaA?: boolean
}>`
	background: linear-gradient(
		110.02deg,
		#d3e1e8 -2.46%,
		#96cfdc 59.07%,
		#97afc4 109.96%
	);
	border-radius: 20px;
	@media (min-width: 1024px) {
		box-shadow: 0px 0px 106px rgba(240, 240, 240, 0.3);
		:after {
			position: absolute;
			bottom: 50%;
			${({ lineaA }) => (lineaA ? 'right: 100%;' : 'left: 100%;')}
			transform: translateY(-50%);
			width: 100vw;
			height: 3px;
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			content: '';
		}
	}
	${tw`
    lg:w-[309px]
    lg:h-[auto]
    w-[354px]
    h-[575px]
    flex flex-col justify-start items-center
    font-montserrat
    text-white
    relative
    lg:mb-[-10px]
    lg:pb-[20px]
    mb-[100px]
    lg:even:ml-auto
`}
@media (max-width:1024px) {
height:auto;
padding-bottom:10px;
}
`

// Контейнер для иконок в карточке

const ImageContainer = styled.div`
	border-radius: 20px;
	display:flex;
	justify-content:center;
	align-items:center;
	@media (max-width: 1023px) {
		img {
			width: 105px;
			height: 115px;
		}
	}
	${tw`
    lg:px-[50px]
    px-[50px]
    py-[20px]
    lg:pt-[20px]
    lg:pb-[22px]
    lg:mt-[15px]
    mt-[40px]
    lg:mb-[10px]
    mb-[44px]
    bg-white
    z-20
    relative
    `}
    @media (min-width:1024px) {
    img {
    width:70%;
    }
    width: calc(100% - 47px) 
    }
`

// Текст на последней карточки в иконке

const TextTriangle = styled.span`
	transform: translate(-50%, -50%);
	${tw`
    absolute
    top-1/2
    left-[53%]
    lg:text-4xl
    text-2xl
    uppercase
    font-semibold
`}
`

// Маски под иконками карточек

const Mask = styled.img<{
	one?: boolean
	two?: boolean
	oneMob?: boolean
	twoMob?: boolean
}>`
	${tw`
    absolute
    z-10
`}
	${({ one, two, oneMob, twoMob }) =>
		one
			? tw`display[none] lg:block right-[150px] top-[18px]`
			: two
			? tw`display[none] lg:block right-[180px] top-[110px]`
			: oneMob
			? tw`lg:display[none] right-[100px] top-0`
			: twoMob
			? tw`lg:display[none] right-[25px] top-[40px]`
			: tw`display[none] lg:block right-[235px] top-[70px]`}
`

// Тайтл карточки

const CardTitile = styled.h2`
	${tw`
    lg:text-xl
    text-xl
    uppercase
    font-medium
    lg:mb-[6px]
    mb-[16px]
`}
`

// Описание карточки
// capitalize
const CardText = styled.p`
	${tw`
    lg:text-lg
    text-lg
    font-normal
    text-center
    lg:px-[17px]
    px-[32px]
	lg:leading-5
`}
`

// Кнопка

const Btn = styled.button`
	@media (min-width: 1024px) {
		transform: translateX(-50%);
	}
	:before {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.1) 100%
		);
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
	${tw`
    before:lg:rounded-[20px]
    before:lg:p-[3px]
    before:rounded-[10px]
    before:p-[2px]
    relative
    lg:ml-[50%]
    lg:p-[32px 67px]
    p-[20px 25px]
	
	transition-all
	duration-500
	hover:opacity-70
	
`}
`

// Текст с кнопкой и линией

const BtnText = styled.p`
	:after {
		display: block;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    after:lg:w-[114px]
    after:lg:h-[3px]
    after:lg:ml-[30px]
    after:w-[67px]
    after:h-[2px]
    after:ml-[25px]
    flex
    items-center
    lg:text-2xl
    font-normal
    text-xxls
    font-syncopate
    uppercase
	
	
`}
`

export const OurProject: React.FC = () => {


	return (
		<Container>
			<Wrapper>
				<Title>Our projects</Title>
				<CardContainer>
					<Card>
						<ImageContainer>
							<img src='./assets/images/card_avatar.png' alt='' />
						</ImageContainer>
						<Mask one src='./assets/images/mask_card_1.png' alt='' />
						<Mask two src='./assets/images/mask_card_2.png' alt='' />
						<Mask src='./assets/images/mask_card_3.png' alt='' />
						<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
						<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
						<CardTitile>Nft sensay</CardTitile>
						<CardText>
							Our NFT project is an integral part of a large and interacting
							ecosystem, the Lumeta ecosystem. Each successive project connects
							to the previous one
						</CardText>
					</Card>
					<Card lineaA>
						<ImageContainer>
							<img src='./assets/vector/card_token.svg' alt='' />
						</ImageContainer>
						<Mask one src='./assets/images/mask_card_1.png' alt='' />
						<Mask two src='./assets/images/mask_card_2.png' alt='' />
						<Mask src='./assets/images/mask_card_3.png' alt='' />
						<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
						<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
						<CardTitile>Token</CardTitile>
						<CardText>
                        Token represents an economic cryptosystem for payment transactions of physical businesses, creation of liquidity and passive income for NFT owners
						</CardText>
					</Card>
					<Card>
                        <ImageContainer>
								<img src='./assets/vector/card_meditation.svg' alt='' />
						</ImageContainer>
						<Mask one src='./assets/images/mask_card_1.png' alt='' />
						<Mask two src='./assets/images/mask_card_2.png' alt='' />
						<Mask src='./assets/images/mask_card_3.png' alt='' />
						<Mask oneMob src='./assets/images/mask_card_1_mob.png' alt='' />
						<Mask twoMob src='./assets/images/mask_card_2_mob.png' alt='' />
						<CardTitile>App - Meditate to earn</CardTitile>
						<CardText>
                        The App - Meditate to Earn is a new round of development and integration of real life, crypto technologies and the path of enlightenment
						</CardText>
					</Card>
				</CardContainer>
				<Link to={'/projects'}>
					<Btn>
						<BtnText>Next projects</BtnText>
					</Btn>
				</Link>
			</Wrapper>
		</Container>
	)
}
