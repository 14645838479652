import styled from 'styled-components'
import tw from 'twin.macro'
import {useState,useEffect,useRef} from "react";
import {Link} from "react-router-dom";


// Видео обертка

const Container = styled.div`
	${tw`
    relative
    p-[0px 0px 54% 0px]
    mb-[78px]
    shadow-[0 100px 100px  #50d8fabf]
`}
@media screen and (max-width:600px) {
padding:0 0 0;
margin-bottom:0;
}
`


// Видео

const Videos = styled.video`
display:none;
@media screen and (min-width:600px) {
display:block;}
	${tw`
  absolute
  top-0
  left-0
  w-full
  h-full
  object-cover
`}
`
const VideosMobile = styled.video`
display:none;
@media screen and (max-width:600px) {
display:block;}
	${tw`
  w-full
  h-[auto]
  object-cover
`}
`
const Demo = styled.div`
${tw`
  absolute
  left-[51.5%] sm:top-[49%]
  sm:w-[19%] sm:h-[34%]
  w-[30%] h-[16%]
  top-[29%]
  flex justify-center items-end
  cursor-pointer
`}
z-index:10;
transform:translate(-50%,-50%)
`
const DemoText = styled.div`
${tw`
sm:mt-[-18px]
	mt-[-10px]
  uppercase
  sm:text-3xl
  text-xl
  font-medium
`}
color: #FFFFFF;
text-shadow:
-0   -1px 0   #6BC9E4,
0   -1px 0   #6BC9E4,
-0    1px 0   #6BC9E4,
0    1px 0   #6BC9E4,
-1px -0   0   #6BC9E4,
1px -0   0   #6BC9E4,
-1px  0   0   #6BC9E4,
1px  0   0   #6BC9E4,
-1px -1px 0   #6BC9E4,
1px -1px 0   #6BC9E4,
-1px  1px 0   #6BC9E4,
1px  1px 0   #6BC9E4,
-1px -1px 0   #6BC9E4,
1px -1px 0   #6BC9E4,
-1px  1px 0   #6BC9E4,
1px  1px 0   #6BC9E4;
`
const DemoImg = styled.img`
${tw`
  sm:w-[60px] h-[auto]
  sm:mb-[0]
  mb-[10px]
  w-[42px]
  mx-[auto]
`}
`
const DemoWrapper = styled.div`
${tw`
 	absolute
  sm:top-[89%]
  top-[100%]
  cursor-pointer
`}
`
export const MainHero: React.FC = () => {


	return (
		<Container>
			<Demo>
				<DemoWrapper>
					<DemoImg src='./assets/vector/new-arrow.svg'/>
					<DemoText> <Link to={`/metaroom`}>enter</Link> </DemoText>
				</DemoWrapper>
			</Demo>
			<Videos autoPlay loop muted playsInline>
				<source
					src='./assets/video/logo_desc.mp4'
					type='video/mp4'
					media='screen and (min-width:600px)'
				/>
			</Videos>
			<VideosMobile autoPlay loop muted playsInline>
				<source
					src='./assets/video/logo_mob_new.mp4'
					type='video/mp4'
					media='screen and (max-width:600px)'
				/>
			</VideosMobile>
		</Container>
	)
}
