import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'

// Контейнер

const Container = styled.section`
	${tw`
	mt-[150px]
    lg:mb-[136px]
    mb-[205px]
`}
	@media (max-width:500px) {
	margin-bottom:156px;
	}
`

// Обертка под тайтл

const Content = styled.div`
	${tw`
    flex flex-col justify-center
    lg:px-[200px]
    lg:mb-[88px]
    mb-[100px]
`}
`

// Тайтл секции

const Title = styled.h1`
	:before {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: white;
		content: '';
	}
	@media (min-width: 1024px) {
		:before {
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}
	${tw`
    before:lg:w-[318px] 
    before:lg:h-[3px]
    before:w-[260px]
    before:h-[2px]
    before:lg:bottom-[-34px]
    before:bottom-[-56px] 
    relative
    font-syncopate
    text-white
    font-normal
    lg:text-5xl
    text-2xl
    uppercase
    text-center
`}
`

// Контейнер иконок

const IconContainer = styled.div`
	:after {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: white;
		content: '';
	}
	@media (min-width: 1024px) {
		:before {
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 1) 100%
			);
		}
	}
	${tw`
    after:lg:w-[574px]
    after:lg:h-[3px]
    after:lg:bottom-[-80px]
    after:w-[260px]
    after:h-[2px]
    after:bottom-[-49px]
    relative
    lg:px-[240px]
`}
	display:grid;
	grid-template-columns:repeat(4,1fr);
	@media (min-width:500px) and (max-width:1000px) {
	max-width:450px;
	margin: 0 auto;
	}
	@media (max-width: 500px) {
		display:grid;
			grid-template-columns:repeat(4,1fr)
		}
		:after {
			bottom:-17px
		}
`

// Иконка

const Icon = styled.div`
	${tw`
    lg:mb-[40px]
    mb-[30px]
    px-[25px]
	duration-300
	hover:opacity-70
	flex justify-center
`}
	@media(min-width: 1024px) {
		img {
			width: 45px;
			height: 45px;
			cursor: pointer;
		}
	}
	@media (max-width: 1023px) {
		img {
			width: 35px;
			height: 35px;
		}
	}
	@media (max-width:500px) {
	img {
			width: 30px;
			height: 28px;
		}
		{
		display:flex;
		justify-content:center;
		padding:0 0 0
		}
	}
`
const Text = styled.p`
${tw`
    lg:text-xl text-center uppercase
    max-w-[500px] mx-[auto]
    mb-[50px]
`}
`
export const BlogSocial: React.FC = () => {
    return (
        <Container>
            <Wrapper>
                <Content>
                    <Title>BLOG & NEWS</Title>
                </Content>
                <Text>You can follow our latest news on social networks or our blog:</Text>
                <IconContainer>
                    <Icon>
                        <a target='_blank' href="https://medium.com/@lumetaworld">
                            <img src='assets/vector/m_social.svg' alt='' />
                        </a>
                    </Icon>
                    <Icon>
                        <a target='_blank' href="https://discord.gg/rU5j53cgp6">
                            <img src='assets/vector/discord_social.svg' alt='' />
                        </a>
                    </Icon>
                    <Icon>
                        <a target='_blank' href="https://t.me/lumetaworld">
                            <img src='assets/vector/tg_social.svg' alt='' />
                        </a>
                    </Icon>
                    <Icon>
                        <a target='_blank' href="https://www.youtube.com/channel/UC4OdzcEe6n3TN7HgrCPcpdA">
                            <img src='assets/vector/youtube_social.svg' alt='' />
                        </a>
                    </Icon>
                    <Icon>
                        <a target='_blank' href="https://twitter.com/LUMETAWorld">
                            <img src='assets/vector/twitter_social.svg' alt='' />
                        </a>
                    </Icon>
                    <Icon>
                        <a target='_blank' href="https://www.facebook.com/LumetaWorld">
                            <img src='assets/vector/fb_social.svg' alt='' />
                        </a>
                    </Icon>
                    <Icon>
                        <a target='_blank' href="https://lumetanft.com/">
                            <img src='assets/images/logo_nft3.png' alt='' />
                        </a>
                    </Icon>
                    <Icon>
                        <a target='_blank' href="https://www.instagram.com/lumeta.world/">
                            <img src='assets/vector/inst_social.svg' alt='' />
                        </a>
                    </Icon>
                </IconContainer>
            </Wrapper>
        </Container>
    )
}