import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'

const Container = styled.section`
	${tw`
    mt-[150px]
    mb-[140px]
    text-center
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-lg text-xxls
    lg:text-left
    lg:mb-[96px] mb-[38px] text-center
    lg:px-[60px] px-[20px]
`}
`
const WrapperInfo = styled.div`
${tw`
`}
`
const InfoItem = styled.div`
${tw`
    flex items-center md:flex-row
    flex-col
`}
img{
    ${tw`
    md:max-w-[75px]
    md:mr-[40px]
    md:mb-[0]
    mb-[20px]
    `}
    }
    p{
    ${tw`
    md:text-left
    md:w-[auto]
    md:max-w-[100%]
    max-w-[400px]
    text-center
    `}
    }
    :not(:last-child) {
    margin-bottom:20px;
}
`

export const RiskHero: React.FC = () => {
    return (
        <Container>
            <Wrapper>
                <Title className='mb-[93px] lg:mb-[122px]'>Risk Warning</Title>
                <Text>
                    Any investment activity in parallel with opportunities also carries risks.
                    To protect yourself, it is recommended to invest carefully and consciously.
                    If you want to replenish your portfolio with NFT tokens, try to protect yourself:
                </Text>
                <WrapperInfo>
                    <InfoItem>
                        <img src="./assets/vector/infoCard.svg" alt=""/>
                        <p>Before buying, be sure to check the information about
                        auctions on the artist's websites and official
                        pages in social networks — many warn in advance that they will sell tokens
                        </p>
                    </InfoItem>
                    <InfoItem>
                        <img src="./assets/vector/infoCard2.svg" alt=""/>
                        <p>The sites where the works are published should be reliable and secure,
                            it is better to buy NFT on verified sites and through verified accounts</p>
                    </InfoItem>
                    <InfoItem>
                        <img src="./assets/vector/infoCard3.svg" alt=""/>
                        <p>Use strong passwords and two-factor authentication official applications and
                            hardware wallets</p>
                    </InfoItem>
                </WrapperInfo>
            </Wrapper>
        </Container>
    )
}