import styled from 'styled-components'
import tw from 'twin.macro'
import { PostTitle } from '../UI/text'
import { Wrapper } from '../wrapper'

const Container = styled.section`
	${tw`
    lg:mb-[149px]
    mb-[100px]
    lg:pl-[65px]
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-2xl text-xxls
    lg:mb-[92px] mb-[50px] text-center lg:text-justify
    xl:pr-[460px] lg:pr-[50px]
`}
`

const CardContainer = styled.div`
	${tw`
    flex lg:justify-between flex-col lg:flex-row
`}
`

const CardContent = styled.div`
	${tw`
    lg:w-1/2
    lg:pr-[30px]
`}
	@media (max-width:450px) {
	margin-left:-10px;
	margin-right:-10px;
	}
`

const Card = styled.div<{
	mr?: boolean
}>`
	border-radius: 20px;
	:after {
		content: '';
		position: absolute;
		background: rgba(255, 255, 255, 0.5);
		filter: blur(25px);
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
	}
	${tw`
    relative
    lg:p-[31px 70px 27px 44px]
    p-[30px 20px 33px 20px]
    bg-white
    z-10
`}
	${({ mr }) => mr && tw`lg:mb-[48px] mb-[30px]`}
`

const CardTitle = styled.h3<{
	twoMr?: boolean
}>`
	${tw`
    text-footer-title
    text-center
    font-bold
    lg:text-xxl
    text-xlxl
    uppercase
`}
	${({ twoMr }) => (twoMr ? tw`mb-[35px] lg:mb-[20px]` : tw`mb-[12px]`)}
`

const CardDescr = styled.p<{
	titul?: boolean
}>`
	background: linear-gradient(180deg, #42809f 0%, #96cfdc 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	${tw`text-center`}
	${({ titul }) =>
		titul
			? `font-size: 20px`
			: `font-size: 16px`}
   
`

export const Philosophie: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<PostTitle className='lg:mb-[92px] mb-[80px] before:w-[682px]'>
					Philosophie of lumeta
				</PostTitle>
				<Text>
					Lumeta is a unique chance to start a new life, be the best version of
					yourself and self-actualize. It is important for us that Lumeta gives
					every user the opportunity to become happier. Lumeta is a unique
					addition to your life.
				</Text>
				<CardContainer>
					<CardContent className='mb-[30px] lg:mb-[initial]'>
						<Card mr>
							<CardTitle>Vision</CardTitle>
							<CardDescr className='px-[5px] lg:px-[initial]'>
								A meta-space ecosystem with limitless possibilities based on
								decentralized blockchain technology.
							</CardDescr>
						</Card>
						<Card>
							<CardTitle>Mission</CardTitle>
							<CardDescr>
								Development of meta-space by introducing and expanding the basic
								functions of being in virtual reality. Development of high-tech
								tools and expansion of users' capabilities for self-realization
								of a personal nature and business direction in virtual reality.<br/><br/>
							</CardDescr>
						</Card>
					</CardContent>
					<CardContent>
						<Card className='h-full'>
							<CardTitle twoMr>Goals</CardTitle>
							<CardDescr titul className='mb-[2px] lg:mb-[5px] lg:text-xl'>
								NFT projects
							</CardDescr>
							<CardDescr className='mb-[10px] lg:mb-[10px]'>
								Capital Raising and brand promotion
							</CardDescr>
							<CardDescr titul className='mb-[2px] lg:mb-[5px]'>
								Token project
							</CardDescr>
							<CardDescr className='mb-[10px] lg:mb-[10px]'>
								The second stage of raising capital to generate passive income
								and create a cryptocurrency
							</CardDescr>
							<CardDescr titul className='mb-[2px] lg:mb-[10px]'>
								Crypto project
							</CardDescr>
							<CardDescr className='lg:mb-[5px]'>
								Universal Payment for Broad Integration
							</CardDescr>
							<CardDescr className='mb-[10px] lg:mb-[20px]'>
								Based on blockchain technology
							</CardDescr>
							<CardDescr
								titul
								className='mb-[2px] lg:mb-[5px] px-[60px] lg:px-[initial]'
							>
								The Lumeta Metaverse project
							</CardDescr>
							<CardDescr>Development of the Lumeta metaspace</CardDescr>
						</Card>
					</CardContent>
				</CardContainer>
			</Wrapper>
		</Container>
	)
}
