import { ProjectsCard } from "../../components/projectsCard";


export default function Projects() {
  return (
    <>
      <ProjectsCard />
    </>
  );
}
