import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'
import {Link} from "react-router-dom";

const Container = styled.section`
	${tw`
    lg:mb-[0]
    mb-[163px]
`}
`

const CardContainer = styled.div`
	${tw`
    flex flex-wrap flex-col lg:h-[927px] lg:w-[974px] lg:mx-[auto]
    content-between
    lg:pl-[25px]
`}
`

const Card = styled.div<{
	one?: boolean
	two?: boolean
	three?: boolean
}>`
	
	border-radius: 20px;
	${tw`
    lg:max-w-md
    h-[min-content]
    bg-white
    xl:px-[17px]
    lg:px-[20px]
    px-[20px]
    flex flex-col items-center text-center
    lg:[&:nth-child(1)]:order-1
    lg:[&:nth-child(2)]:order-3
    lg:[&:nth-child(3)]:order-2
    xl:[&:nth-child(3)]:pb-[31px]
    lg:[&:nth-child(4)]:order-4
    [&:nth-child(1)]:order-1
    [&:nth-child(2)]:order-2
    [&:nth-child(3)]:order-3
    [&:nth-child(4)]:order-4
`}

	${({ one, two, three }) =>
		one
			? tw`lg:pt-[5px] pt-[33px] pb-[43px] lg:pb-[5px] mb-[56px] lg:mb-[36px]`
			: two
			? tw`lg:pt-[5px] pt-[27px] pb-[37px] lg:pb-[5px] mb-[46px] lg:mb-[36px]`
			: three
			? tw`pt-[30px] lg:pt-[5px] pb-[31px] lg:pb-[5px] mb-[51px] lg:mb-[36px]`
			: tw`lg:pt-[5px] pt-[29px] pb-[43px] lg:pb-[5px]`}
			
			@media (max-width:370px) {
			    padding-left: 10px;
    			padding-right: 10px;
			}
			img {
			margin: 10px 0 15px;}
`

const CardTitle = styled.h3`
	${tw`
    text-footer-title
    font-bold
    lg:text-xl
    text-xlxl
    uppercase
`}
`

const CardDescr = styled.p`
	background: linear-gradient(180deg, #42809f 0%, #96cfdc 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	${tw`
    lg:text-lg
    text-lgs
    lg:mb-[10px]
`}
	@media (max-width:370px) {
				padding-left: 10px;
    			padding-right: 10px;
	}
`

export const InvestorsCard: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<CardContainer>
					<Card one>
						<img src='assets/vector/investors_icon_1.svg' alt='' />
						<CardTitle className='mb-[20px] mt-[25px] lg:mb-[16px] lg:mt-[0] px-[20px] xl:px-[50px]'>
							Strategy and management of the company
						</CardTitle>
						<CardDescr className='mb-[20px] px-[30px] lg:px-[initial]'>
							Strategy and management of the company
						</CardDescr>
						<CardDescr className='mb-[20px]'>
							Corporate Governance
						</CardDescr>
						<CardDescr className='mb-[20px] '>
							Sustainable Growth Strategy
						</CardDescr>
						<CardDescr className='mb-[20px] '>
							Financial Management
						</CardDescr>
						<CardDescr>ESG Environmental Strategy</CardDescr>
						<CardDescr>
							<Link to={`/annualreport`}><b>Annual reports</b></Link>
						</CardDescr>
					</Card>
					<Card two>
						<img src='assets/vector/investors_icon_2.svg' alt='' />
						<CardTitle className='mb-[20px] lg:mb-[16px] mt-[25px] lg:mt-[0]'>
							Cryptocurrency instruments
						</CardTitle>
						<CardDescr className='mb-[20px]'>White paper</CardDescr>
						<CardDescr className=''>
							Cryptocurrency Quotes and Charts
						</CardDescr>
						<CardDescr className=' px-[15px] lg:px-[30px] xl:px-[50px]'>
							Quarterly/Annual financial statements
						</CardDescr>
						<CardDescr className=' xl:px-[50px]'>
							Presentation of development and growth prospects
						</CardDescr>
						<CardDescr className=''>
							Financial indicators of Crypto Products
						</CardDescr>
						<CardDescr className=''>
							Strategy and Money Management
						</CardDescr>
						<CardDescr className=''>
							Stacking and passive earnings
						</CardDescr>
						<CardDescr>Advantages</CardDescr>
					</Card>
					<Card three>
						<img src='assets/vector/investors_icon_2.svg' alt='' />
						<CardTitle className='mb-[20px] mt-[25px] lg:mb-[16px] lg:mt-[0]'>
							Exchange instruments
						</CardTitle>
						<CardDescr className='mb-[20px] '>
							Quotes and charts of stocks and indices
						</CardDescr>
						<CardDescr className='mb-[20px] '>
							Quarterly/Annual financial statements
						</CardDescr>
						<CardDescr className='mb-[20px]  xl:px-[50px]'>
							Presentation of development and growth prospects
						</CardDescr>
						<CardDescr className='mb-[20px] '>
							Analytical reports
						</CardDescr>
						<CardDescr className='mb-[20px] '>
							Financial indicators
						</CardDescr>
						<CardDescr>Dividends</CardDescr>
					</Card>
					<Card>
						<img src='assets/vector/investors_icon_4.svg' alt='' />
						<CardTitle className='mb-[20px] mt-[20px] lg:mb-[16px] lg:mt-[0] px-[0] lg:px-[initial]'>
							Charitable activities of the Lumeta Foundation
						</CardTitle>
						<CardDescr className='mb-[20px] '>
							Structure and growth of the fund
						</CardDescr>
						<CardDescr className='mb-[20px] '>
							Directions of financial flows
						</CardDescr>
						<CardDescr>Projects</CardDescr>
					</Card>
				</CardContainer>
			</Wrapper>
		</Container>
	)
}
