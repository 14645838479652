import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'
import {useState} from "react";

const Container = styled.section`
	${tw`
    mt-[150px]
    mb-[140px]
    text-center
`}
`


const List = styled.ul`
	${tw`
    lg:flex lg:flex-col lg:items-center
`}
`

const Item = styled.li<{
    activeItem?: boolean
}>`
	${tw`
    lg:rounded-[20px]
    rounded-[10px]
    mb-[31px] 
    bg-white
    lg:w-[80%]
`}
cursor:pointer;
	@media(min-width: 1024px) {
		${({ activeItem }) =>
    activeItem && 'box-shadow: 0px 8px 50px rgba(255, 255, 255, 0.25);'}
	}
	@media (max-width: 1023px) {
		box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);
	}
`

const QuestContainer = styled.div<{
    activeTitle?: boolean
}>`
	border-radius: 20px;
	@media (min-width: 1024px) {
		${({ activeTitle }) =>
    activeTitle
        ? 'box-shadow: 0px 8px 28px rgba(226, 226, 226, 0.25);'
        : 'box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);'}
	}
	@media (max-width: 1023px) {
		${({ activeTitle }) =>
    activeTitle && 'box-shadow: 0px 8px 28px rgba(214, 214, 214, 0.25);'}
	}
	${tw`
    lg:p-[18px 38px 19px 31px]
    p-[16px 16px 16px 22px]
    flex justify-between items-center
`}
`

const Text = styled.p`
	${tw`
    lg:text-xl
    text-lgsm
    font-normal
    font-montserrat
    text-faq
    cursor-pointer
    mr-[auto]
    uppercase
`}
@media (max-width:500px) {
text-align:left;}
`

const AskContainer = styled.div<{
    active?: boolean
}>`
	animation-name: open;
	animation-duration: 0.8s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	animation-delay: 0s;
	@keyframes open {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	${({ active }) => (active ? tw`block` : tw`display[none]`)}
	${tw`lg:p-[30px 32px 41px] p-[19px 19px 22px]`}
`


const Icon = styled.div`
	box-shadow: 0px 4px 8px rgba(225, 225, 225, 0.25);
	${tw`
    bg-white w-[55px] h-[55px]
    flex justify-center items-center
    rounded-full
    cursor-pointer
`}
`
const Qwerty = styled.p`
	${tw`
    font-montserrat
    font-normal
    lg:text-xl
    text-left
    text-lgs
    tracking-negative
    text-faq-ask
`}
`
export const LearnHero: React.FC = () => {
    const [fedOpenOne, setFedOpenOne] = useState<boolean>(false)
    const [fedOpenTwo, setFedOpenTwo] = useState<boolean>(false)
    const [fedOpenThree, setFedOpenThree] = useState<boolean>(false)
    const [fedOpenFour, setFedOpenFour] = useState<boolean>(false)
    const [fedOpenFive, setFedOpenFive] = useState<boolean>(false)
    const [fedOpenSix, setFedOpenSix] = useState<boolean>(false)

    return (
        <Container>
            <Wrapper>
                <Title className='mb-[93px] lg:mb-[122px]'>LEARN</Title>
                <List>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenOne(!fedOpenOne)} >
                            <Text >What is NFT?</Text>
                            <Icon >
                                <img src='./assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenOne}>
                            <Qwerty>Non fungible token (NFT) is a financial asset consisting of digital data stored in a blockchain. Ownership of an NFT is registered on the blockchain and can be transferred by the owner, allowing NFTs to be sold and exchanged. NFTs typically contain links to digital files such as photos, audio and video. Because NFTs are unique assets, they differ from cryptocurrencies, which are fungible.</Qwerty>
                        </AskContainer>
                    </Item>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenTwo(!fedOpenTwo)} >
                            <Text >How to buy NFT?</Text>
                            <Icon >
                                <img src='./assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenTwo}>
                            <Qwerty>Lumetanft.com has instructions on how to buy NFTs
                            </Qwerty>
                        </AskContainer>
                    </Item>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenThree(!fedOpenThree)} >
                            <Text >What is a token?</Text>
                            <Icon >
                                <img src='./assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenThree}>
                            <Qwerty>A token refers to a special virtual currency.
                                These tokens are interchangeable and tradable assets or utilities in their own blockchains.
                                Tokens are often used to raise funds for mass sales,
                                but they can also serve as a substitute for other cryptocurrencies.</Qwerty>
                        </AskContainer>
                    </Item>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenFour(!fedOpenFour)} >
                            <Text >What is blockchain?</Text>
                            <Icon >
                                <img src='./assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenFour}>
                            <Qwerty>A blockchain is a distributed database or registry shared by nodes in a computer network. As a database, a blockchain stores information in electronic form. Blockchains are best known for their important role in cryptocurrency systems, such as Bitcoin, for secure and decentralized transaction accounting. The innovation in blockchain is that it guarantees the accuracy and security of data recording and creates trust without the need for a trusted third party.</Qwerty>
                        </AskContainer>
                    </Item>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenFive(!fedOpenFive)} >
                            <Text >What is cryptocurrency?</Text>
                            <Icon >
                                <img src='./assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenFive}>
                            <Qwerty>A cryptocurrency is a digital or virtual currency that is protected by cryptography, making it virtually impossible to counterfeit. Many cryptocurrencies are decentralized networks based on blockchain technology supported by a diverse network of computers. A distinctive feature of cryptocurrencies is that they are generally not issued by a central authority, making them theoretically immune to government intervention or manipulation.</Qwerty>
                        </AskContainer>
                    </Item>
                    <Item >
                        <QuestContainer onClick={e=>setFedOpenSix(!fedOpenSix)} >
                            <Text >What is a metaverse?</Text>
                            <Icon >
                                <img src='./assets/vector/chevron_down.svg' alt='' />
                            </Icon>
                        </QuestContainer>
                        <AskContainer active={fedOpenSix}>
                            <Qwerty>A metaverse is a network of three-dimensional virtual worlds. These worlds are accessed through a virtual reality headset - users navigate through the metaverse using eye movements, feedback controllers or voice commands. With the headset, the user is immersed in the metaspace and feels a so-called presence, which arises by creating a physical sense of real presence.</Qwerty>
                        </AskContainer>
                    </Item>
                </List>
            </Wrapper>
        </Container>
    )
}