import styled from 'styled-components'
import tw from 'twin.macro'
import { PostTitle, Title } from '../UI/text'
import { Wrapper } from '../wrapper'

// Контейнер секции

const Container = styled.section`
	${tw`
    lg:mt-[162px]
    mt-[85px]
    lg:mb-[143px]
    mb-[349px]
    lg:pl-[63px]
    relative
`}
`

// Текст Hero
//lg:mb-[89px] lg:pr-[70px]
const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	@media (max-width: 1023px) {
		padding: 0 8px;
	}
	${tw`
    lg:text-2xl text-xxls
    font-normal
    mb-[43px]
    lg:pr-[70px]
    xl:pr-[217px] lg:text-justify text-center
`}
`

// Список

const List = styled.ul`
	@media (max-width: 1023px) {
		margin: 0 -10px;
	}
	${tw`
    z-10
`}
`

// Один элемент в списке

const Item = styled.li`
	${tw`
    lg:text-2xl
    text-xxls text-left lg:text-left
    opacity-90
    before:lg:pr-[20px]
    before:pr-[10px]
    before:lg:pl-[15px]
    before:lg:content-['•']
    before:content-['•']
`}
`

const Image = styled.img<{
	imgMob?: boolean
}>`
	${tw`
    absolute
`}
	${({ imgMob }) =>
		imgMob
			? tw`lg:display[none] bottom-[-325px] right-0`
			: tw`bottom-[-380px] right-0 display[none] lg:block`}
`

export const AboutHero: React.FC = () => {
	return (
		<Container>
			<Wrapper>
				<Image imgMob src='assets/images/philosoph_lumeta_mob.png' alt='' />
				<Image src='assets/images/philosoph_lumeta_desc.png' alt='' />
				<Title>About</Title>
				<PostTitle className='lg:mt-[110px] mt-[82px] mb-[56px] lg:mb-[89px] before:w-[371px]'>
					Lumeta
				</PostTitle>
				<Text>
                Lumeta is the synergy of Lum (Light) and meta (Metaverse). Lumeta symbolizes metaverse, 
accessible to everyone and opening up limitless possibilities, contributing to the expansion of the boundaries of consciousness. Lumeta will enable absolutely everyone to realize their creative potential and find themselves. Lumeta is an entire ecosystem with a wide range of interactions for users, namely: 
				</Text>
				<List>
                    <Item>Advertising & Business</Item>
                    <Item>Leisure & Entertainment</Item>
                    <Item>Self-Development & Education</Item>
                    <Item>Creation of your own tokens, cryptocurrencies, and NFTs</Item>
                    <Item>Creation of NFT Gallery</Item>
                    <Item>Content creation</Item>
                    <Item>Introduction of karma in the metaverse</Item>
                    <Item>Education of moral and spiritual values in media space & reality</Item>
                    <Item>Play to Earn</Item>
                    <Item>And more ..</Item>
				</List>
			</Wrapper>
		</Container>
	)
}
