export const Links = [
	{
		link: [
			{name:'about us',
				href:''
			},
			{name:'about',
				href:'/about'
			},
			{name:'careers',
				href:'/careers'},
			{name:'business contacts',
				href:'/business'},
			{name:'Blog & News',
				href:'/blog'},
			{name:'terms',
				href:'/terms'},
			{name:'privacy',
				href:'/privacy'},
			{name:'risk warning',href:'/risk'},
		],
	},
	{
		link: [
			{name:'products',
			href:''},
			{name:'nft',
			href:'https://lumetanft.com/'},
			{name:'token',
			href:'/projects'},
			{name:'app-meditate to earn',
			href:'https://app.lumeta.io/'},
			{name:'crypto',
			href:'/projects'},
			{name:'swap',
			href:'/projects'},
			{name:'lumeta metaverse',
			href:'/projects'},
		],
	},
	{
		link: [
			'community',
			{
				src: [
					'assets/vector/discord_com.svg',
					'assets/vector/twitter_com.svg',
					'assets/vector/tg_com.svg',
					'assets/vector/inst_com.svg',
					'assets/vector/fb_com.svg',
					'assets/images/logo_nft3.png',
					'assets/vector/youtube_com.svg',
					'assets/vector/m_com.svg',
				],
				links: [
					{name:'Community',
						href:'',img:null},
					{	href:'https://discord.gg/rU5j53cgp6',
						img:'assets/vector/discord_com.svg' },
					{	href:'https://twitter.com/LUMETAWorld',
						img:'assets/vector/twitter_com.svg' },
					{	href:'https://t.me/lumetaworld',
						img:'assets/vector/tg_com.svg' },
					{	href:'https://www.instagram.com/lumeta.world/',
						img:'assets/vector/inst_com.svg' },
					{	href:'https://www.facebook.com/LumetaWorld',
						img:'assets/vector/fb_com.svg' },
					{	href:'https://lumetanft.com/',
						img:'assets/images/logo_nft3.png' },
					{	href:'https://www.youtube.com/channel/UC4OdzcEe6n3TN7HgrCPcpdA',
						img:'assets/vector/youtube_com.svg' },
					{	href:'https://medium.com/@lumetaworld',
						img:'assets/vector/m_com.svg' },
				],
			},
		],
	},
	{
		link: [{name:'support',href:''}, {name:'feedback',href:'/feedback'},{name:'learn',href:'/learn'}],
	},
	{
		link: [{name:'contacts'}, { btn: 'info@lumeta.io' }],
	},
]
