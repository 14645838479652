export const Ask = [
    {
        title: "Who owns the commercial rights?",
        descr: "Copyright belongs to Lumeta. For commercial use, please contactBusiness us!"
    },
    {
        title: "What makes Lumeta unique?",
        descr: "Our company provides a unique opportunity to use new technologies of blockchain, NFT with passive income and metaverse to create a new identity in the technical world."
    },
    {
        title: "Where can I find support?",
        descr: "Email info@lumeta.io + Social networks + Discord channels"
    },
    {
        title: "What long-term developments does Lumeta have?",
        descr: "There will be two more collections of NFT Buddhas with their own token, cryptocurrency, and metaverse. Each project will be a part of a great ecosystem. For more information, visit the Road Map section."
    },
    {
        title: "How many NFT collections will there be?",
        descr: "At the moment 3 collections related to Buddha are planned. Each NFT collection is connected to the next by a transformation. The third collection will be made in 3D and can be used in metaverse as your avatar."
    },
    {
        title: "How many people are there in the team?",
        descr: "Our core team consists of 7 people. There are more than 20 other hired employees around the world."
    },
    {
        title: "Can I become a part of your team?",
        descr: "We are looking for creative minds to create and conquer new horizons with us. For more information, email info@lumeta.io"
    },
    {
        title: "What is Lumeta's goal?",
        descr: "Our goal is to create a completely unique metaverse with unlimited possibilities. We are going on a very long journey and invite you to join us!"
    },
    {
        title: "Do you do any charity work?",
        descr: "We have developed an elaborate and very detailed financial plan. 10% of our profits go to the Lumeta Charitable Foundation. The funds in the fund accumulate additional profits through dividend-paying investment instruments. Part of the dividends are reinvested in the fund for additional growth of the charitable foundation, and another part is used for various charitable projects, such as donations to flora and fauna, hunger, education and support for young start-ups."
    },
    {
        title: "How can I support the project?",
        descr: "By buying our NFTs you are investing in our and your future. We are also interested in a large investment, for that contactBusiness us at business@lumeta.io. And of course you can become part of our team if you are creative, energetic and professional in our field."
    },
    {
        title: "How can I keep up with news?",
        descr: "You can find the latest news on Twitter, Instagram, Telegram"
    },
    {
        title: "Where can I ask questions about cooperation?",
        descr: "Email us at business@lumeta.io"
    }
]