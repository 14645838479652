import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'
import {useState} from "react";
import {ButtonCopied} from "../UI/btn";

const Container = styled.section`
	${tw`
    mt-[150px]
    mb-[140px]
    text-center
`}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
	${tw`
    lg:text-xl text-xxls
    lg:mb-[61px] mb-[38px] text-center
    lg:px-[60px] px-[20px]
`}
`



export const ContactHero: React.FC = () => {
    const [active,setActive] = useState<boolean>(false)
    const btn = document.querySelector('.btn-copie') as HTMLButtonElement | null;
    btn?.addEventListener('click', (e) => {
        let text:any = e.target;
        navigator.clipboard.writeText(text.innerHTML);
        setActive(true)
        setTimeout(() => {
            setActive(false)
        },500)
        console.log(active)
    })
    return (
        <Container>
            <Wrapper>
                <Title className='mb-[93px] lg:mb-[122px]'>Contacts</Title>
                <Text>
                    For questions about cooperation, please contact us by:
                </Text>
                <ButtonCopied name='info@lumeta.io'/>
            </Wrapper>
        </Container>
    )
}