import styled from 'styled-components'
import tw from 'twin.macro'
import { Title } from '../UI/text'
import { Wrapper } from '../wrapper'

const Container = styled.section`
	${tw`
    lg:mt-[150px] mt-[80px]
    lg:mb-[207px] mb-[96px]
`}
`

const Content = styled.div`
	${tw`
    flex flex-col lg:flex-row lg:justify-center lg:items-center
    sm:w-[974px] sm:mx-[auto] sm:pl-[10px]
`}
`

const Image = styled.img<{
    mob?: boolean
}>`
	${tw`
	lg:max-w-xs
	`}
	
	${({ mob }) => (mob ? tw`lg:display[none]` : tw`display[none] lg:block`)}
`

const Text = styled.p`
	text-shadow: 0px 4px 30px rgba(91, 176, 255, 0.25);
    justify-content: center !important;
	${tw`
    lg:text-lg
    text-xxls
    font-normal
    px-[18px] lg:px-[initial]
    xl:pl-[75px]
    xl:pr-[90px]
    lg:text-left text-center
`}
`

export const AnualHero: React.FC = () => {
    return(
        <Container>
            <Wrapper>
                <Title className='mb-[95px] lg:mb-[101px]'>Annual reports</Title>
                <Content>
                    <a href="assets/Annual Report 2022.pdf" target='_blank'>
						<Image src='assets/images/anual.png' alt='' />
                        <Image mob src='assets/images/anual_mobile.png' alt='' />
					</a>
                    {/* <Text>
                        Integration of the ecosystem according to the type of planetary
                        system with the possibility of interaction with project planets.
                        <br /> The NET SENSAY project originates, which is an integral part
                        of a large and interacting ecosystem, the Lumeta ecosystem.
                    </Text> */}
                    <Text>
                    Final report of Lumeta's activities for 2022
                    </Text>
                </Content>

                {/* <br/><br/>
                <Title className='mb-[95px] lg:mb-[101px]'>Statistic</Title>
                <Content>
                    <a href="assets/Statistic 2022.pdf" target='_blank'>
						<Image src='assets/images/statistic.png' alt='' />
                        <Image mob src='assets/images/statistic_mob.png' alt='' />
					</a>
                </Content> */}
            </Wrapper>
        </Container>
    )
}
