import { InvestorsCard } from "../../components/investorsCard";
import { InvestorsHero } from "../../components/investorsHero";
import {MusicPlayer} from "../../components/musicPage";


export default function Investors() {
  return (
    <>
      <InvestorsHero />
      <InvestorsCard />
    </>
  );
}
