import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'
import { Link } from 'react-router-dom'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Контейнер блога
const Container = styled.section`
	${tw`
    lg:mb-[152px]
    mb-[88px]
    w-screen
    flex flex-col justify-center
`}
`

// Контейнер текста в карточке блога
const TextContainer = styled.div`
	${tw`
    lg:mt-[21px]
    mt-[33px]
`}
`

// Тайтл секции
const Title = styled.h1`
	:before {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -25px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[450px]
    before:w-[243px]
    before:lg:h-[3px]
    before:h-[2px]
    relative
    font-syncopate
    text-white
    font-normal
    lg:text-5xl
    text-4xl
    uppercase
    text-center
    lg:mb-[100px]
    mb-[63px]
`}
`

// Контейнер поста
const PostContainer = styled.div`
	${tw`
    rounded-[20px]
    bg-[#F8F8F8]
    md:py-[36px]
    md:px-[85px]
    md:mx-[auto]
    p-[30px 20px 20px 20px]
`}
max-width:700px;
`

const PostImg = styled.img<{
    imgMob?: boolean
}>`
	${({ imgMob }) =>
    imgMob
        ? tw`lg:display[none] md:w-full md:h-auto`
        : tw`lg:flex content-center lg:w-[100%] m-auto display[none]`}
			border-radius:10px;
`

// Тайтл поста
const TitleTwo = styled.h2`
	${tw`
    font-montserrat
    text-tertiary
    font-semibold
    lg:text-2xl
    text-xxls
    lg:mb-[7px]
    mb-[15px]
`}
`

// Описание поста
const Text = styled.p`
	${tw`
    font-montserrat
    font-normal
    text-post
    lg:text-xl
    text-lgs
    lg:mb-[20px]
    mb-[11px]
`}
`

// Дата поста
const TextData = styled.div`
	${tw`
    font-syncopate
    lg:text-lg
    text-sm
    font-bold
    text-post-gray
    text-right
`}
`
const WrapperDate = styled.div`
${tw`
flex justify-between items-center
`}
`

const Btn = styled.button`
background-color:#9170f1;
${tw`
text-white rounded-2xl font-bold
w-[183px]
h-[57px]
`}
@media (max-width:500px) {
width:160px;
font-size:16px}
`
export const BlogHero: React.FC = () => {
    return (
        <Container>
            <Wrapper>
                <PostContainer className='mt-[80px]'>
                    <PostImg  src='./assets/images/blog/3.jpg' alt='' />
                    <PostImg imgMob className='mx-[auto]' src='./assets/images/blog/3.jpg' alt='' />
                    <TextContainer>
                        <TitleTwo>Innovative Metacompany Lumeta: Forward-Thinking Solutions for the Future</TitleTwo>
                        <Text>
                            Lumeta embarked on its journey as a startup focused on researching and implementing cutting-edge technologies. In a short span of time, the company assembled a team of enthusiasts and experts driven by the idea of crafting unique and innovative products. Over the course of more than 1.5 years, Lumeta diligently worked on its projects, evolving them from concepts to fully-fledged products.
                        </Text>
                        <WrapperDate>
                            <TextData>07/08/22</TextData>
                            <Link to={'https://medium.com/@lumetaworld/innovative-metacompany-lumeta-forward-thinking-solutions-for-the-future-d5907366f979'}>
                                <Btn>GET MORE INFORMATION</Btn>
                            </Link>
                        </WrapperDate>
                    </TextContainer>
                </PostContainer>
                <PostContainer className='mt-[20px]'>
                    <PostImg  src='./assets/images/blog/2.jpg' alt='post' />
                    <PostImg imgMob className='mx-[auto]' src='./assets/images/blog/2.jpg' alt='post' />
                    <TextContainer>
                        <TitleTwo>Lumeta presents: The Way of Cleansing and Enlightenment</TitleTwo>
                        <Text>
                            The year 2023 is upon us, which means that it is time to go through spiritual practices! It is important to move consciously toward the light and clear your “perception”.
                        </Text>
                        <WrapperDate>
                            <TextData>12.02.2023</TextData>
                            <Link to={'https://medium.com/@lumetaworld/lumeta-presents-the-way-of-cleansing-and-enlightenment-3171fef70936'}>
								<Btn>GET MORE INFORMATION</Btn>
							</Link>
                        </WrapperDate>
                    </TextContainer>
                </PostContainer>
                <PostContainer className='mt-[20px]'>
                    <PostImg  src='./assets/images/blog/1.jpg' alt='' />
                    <PostImg imgMob className='mx-[auto]' src='./assets/images/blog/1.jpg' alt='' />
                    <TextContainer>
                        <TitleTwo>The birth of Lumeta Metacompany. Technology | Digital Art NFT | Inspiration</TitleTwo>
                        <Text>
                            In anticipation of creating something unique and template-breaking for our world, we took modern blockchain technology paired with NFT digital art, added innovation and opportunities in the form of passive income and the realization of creativity for each of us, blended it all together and created a company called Lumeta.
                        </Text>
                        <WrapperDate>
                            <TextData>12.03.2022</TextData>
                            <Link to={'https://medium.com/@lumetaworld/the-birth-of-lumeta-metacompany-technology-digital-art-nft-inspiration-3d98f374c904'}>
									<Btn>GET MORE INFORMATION</Btn>
							</Link>
                        </WrapperDate>
                    </TextContainer>
                </PostContainer>
            </Wrapper>
        </Container>
    )
}