import { ShopHero } from "../../components/shopHero";


export default function Shop() {
  return (
    <>
      <ShopHero />
    </>
  );
}
