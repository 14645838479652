import styled from 'styled-components'
import { Link } from 'react-router-dom'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactModal from 'react-modal';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import "swiper/css/thumbs";

import { useState } from "react";

import { Navigation, Pagination, FreeMode } from "swiper";
// Контейнер блога
//min-[320px]: max-[320px]:

//margin-top: 750px;
const Container = styled.section`
	${tw`
    // lg:mb-[152px]
    // mb-[88px]

    // lg:mt-[300px]
    // mt-[550px]
    // max-[600px]:mt-[750px]
    w-screen
    flex flex-col justify-center
`}
`

// Тайтл секции

const Title = styled.h1`
	:before {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -25px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[450px]
    before:w-[243px]
    before:lg:h-[3px]
    before:h-[2px]
    relative
    font-syncopate
    text-white
    font-normal
    lg:text-5xl
    text-4xl
    uppercase
    text-center
    lg:mb-[100px]
    mb-[63px]
`}
`

// Контейнер поста

const PostContainer = styled.div`
cursor: pointer;
	${tw`
    rounded-[20px]
    bg-[#F8F8F8]
    md:py-[36px]
    md:px-[36px]
    md:mx-[auto]
    p-[30px 20px 20px 20px]
`}
max-width:900px;
`

// tw`lg:display[none] md:w-full md:h-auto`
//: tw`lg:flex content-center lg:w-[100%] m-auto display[none]`}
// img, video {
//     max-width: 100%;
//     max-height: 380px;
// }
const PostImg = styled.img<{
	imgMob?: boolean
}>`
	${({ imgMob }) =>
		imgMob
			? tw`lg:display[none] md:w-full md:h-auto`
			: tw`lg:flex content-center lg:w-[100%] lg:h-[380px] m-auto display[none]`}
			border-radius:10px;
`

const Btn = styled.button`
	:before {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.1) 100%
		);
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
	cursor: pointer;
	${tw`
    before:lg:rounded-[20px]
    before:lg:p-[3px]
    before:rounded-[10px]
    before:p-[2px]
    relative
    lg:p-[9px 28px]
    p-[18px 38px]
    lg:text-2xl
    font-normal
    text-xxls
    font-syncopate
    uppercase
   
`}
`

const BlockFull = styled.div`
display:none;
@media screen and (min-width:600px) {
display:block;}
`

const BlockSmall = styled.div`
display:none;
@media screen and (max-width:600px) {
display:block;}
`

const PaddingTop = styled.div`
// padding-top: 120px;
${tw`
//lg:mt-[100px]
//md:mt-[140px]
mt-[140px]
`}
`

const TitleImages = styled.h1`
	:before {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -25px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[450px]
    before:w-[243px]
    before:lg:h-[3px]
    before:h-[2px]
    relative
    font-syncopate
    text-white
    font-normal
    lg:text-5xl
    text-2xl
    uppercase
    text-center
    lg:mb-[100px]
    mb-[63px]
`}
`

export const MetaSliderHero: React.FC = () => {
    const images = [
        './assets/images/slider/1.png',
        './assets/images/slider/2.png',
        './assets/images/slider/3.png',
        './assets/images/slider/4.png',
        './assets/images/slider/5.png',
        './assets/images/slider/6.png',
        './assets/images/slider/7.png',
        './assets/images/slider/8.png',
        './assets/images/slider/9.png',
        // Add more image URLs here
      ];

      const [popupOpen, setPopupOpen] = useState<boolean>(false);
      const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    
      const openPopup = (index: number) => {
        window.history.pushState(null, "", window.location.pathname + '#'+index);

        // close modal on 'back'
        window.onpopstate = () => {
            window.onpopstate = () => {}
            setPopupOpen(false)
        }

        setSelectedImageIndex(index);
        setPopupOpen(true);
      };
    
      const closePopup = () => {
        setPopupOpen(false);
      };
  

	return (
		<>
		<Container>
			<Wrapper>
                <PaddingTop/>
                <TitleImages>IMAGES PREVIEW</TitleImages>

				<Swiper
				    pagination={{ clickable: true }}
					navigation={true} modules={[FreeMode, Navigation, Pagination]}
				    >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <PostContainer onClick={() => openPopup(index)}>
                                <PostImg  src={image} />
                                <PostImg imgMob className='mx-[auto]' src={image} />
                            </PostContainer>
                        </SwiperSlide>
                    ))}
				</Swiper>

                <Title>
                    <BlockFull><br></br></BlockFull><br></br>
                    <Link to={'https://meta.lumeta.io/'}>
                        <Btn>OPEN METAROOM</Btn>
                    </Link>
                </Title>
			</Wrapper>
		</Container>

        <ReactModal 
        isOpen={popupOpen} 
        onRequestClose={closePopup}

        ariaHideApp={false}
        contentLabel="Selected Option"

        style={{
            overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: '99',
            },
            content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90%',
            maxHeight: '90%',
            },
        }}
        >
            <Swiper
                className='modalPopup'
                pagination={{ clickable: true }}
                navigation={true} modules={[FreeMode, Navigation, Pagination]}
                spaceBetween={10}
                slidesPerView={1}
                initialSlide={selectedImageIndex}
                onSlideChange={(swiper) => setSelectedImageIndex(swiper.activeIndex)}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image} alt={`img ${index + 1}`}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </ReactModal>

		</>
	)
}
