import { BlogsNews } from "../../components/blogsNews";
import { EcoSystem } from "../../components/ecosystem";
import { Follow } from "../../components/follow";
import { MainHero } from "../../components/mainHero";
import { OurProject } from "../../components/ourProject";
import { WeAre } from "../../components/weAre";



export default function Home() {
  return (
    <>
      <MainHero />
      <WeAre />
      <BlogsNews />
      <EcoSystem />
      <OurProject />
      <Follow />
    </>
  );
}
