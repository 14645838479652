import styled from 'styled-components'
import tw from 'twin.macro'
import { Wrapper } from '../wrapper'

// Секция планет

const Container = styled.section`
	${tw`
    lg:mb-[100px]
    mb-[36px]
`}
`

// Контейнер под тайтл

const Content = styled.div`
	${tw`
    flex flex-col justify-center
`}
`

// Тайтл

const Title = styled.h1`
	:before {
		position: absolute;
		left: 0px;
		left: 50%;
		transform: translateX(-50%);
		bottom: -25px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		content: '';
	}
	${tw`
    before:lg:w-[450px]
    before:lg:h-[3px]
    before:w-[243px] 
    before:h-[2px]
    relative
    font-syncopate
    text-white
    font-normal
    lg:text-5xl
    text-4xl
    uppercase
    text-center
    lg:mb-[50px]
`}
`

// Картинка мобильная и десктоп

const ImageEco = styled.img<{
	imgMob?: boolean
}>`
	${tw`
    w-full h-full
`}
 @media (min-width:764px) {
 	width:70%;
 	margin: 0 auto;
 }
`

export const EcoSystem: React.FC = () => {
	return (
		<Container>
			<Content>
				<Wrapper>
					<Title>Ecosystem</Title>
					{/*<ImageEco src='assets/images/ecosystem.svg' alt='' />*/}
					<ImageEco src='assets/vector/ecos.svg' alt='' />
				</Wrapper>
				{/*<ImageEco imgMob src='assets/images/ecosystem_mob.png' alt='' />*/}
			</Content>
		</Container>
	)
}
