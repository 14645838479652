import React, {useState} from 'react';
import styled from "styled-components";
import tw from "twin.macro";

const Btn = styled.button`
	background: linear-gradient(110.02deg, #D3E1E8 -2.46%, #96CFDC 59.07%, #97AFC4 109.96%);
border-radius: 10px;
	${tw`
    relative
    lg:p-[13px 28px]
    p-[18px 38px]
    lg:text-2xl
    font-normal
    text-xxls
    font-syncopate
    uppercase
   
`}
@media (max-width:450px){
font-size:16px;

}
p{
${tw`
absolute
sm:top-[50%] sm:left-[105%]
top-[100%]
left-[50%]
`}
@media (max-width:600px) {
transform:translate(-50%,0);
}
transform:translate(0,-50%);
}
`
interface myProp {
    name:string,
}

export const ButtonCopied:  React.FC<myProp> = (props) => {
    const [active,setActive] = useState<boolean>(false)
    function tester(e:any) {
        let text:any = e.target;
        let child:any = document.querySelector('.btn-span') as HTMLSpanElement | null;
        setActive(true)
        setTimeout(() => {
            setActive(false)
        },500)
        if( typeof (navigator.clipboard) != 'undefined') {
        navigator.clipboard.writeText(child.innerHTML);
        }
    }
    return (
        <Btn className='' onClick={tester}><span className='btn-span'>{props.name}</span>
            <p className={active?'block':'hidden'}>copied</p></Btn>
    );
};

